<template>
  <ul>
    <li v-for="item in data" :key="item.taskIdStr" style="background-color: white;">
      <XRecyclerNode :data="item" />
      <XRecyclerItem v-if="item.list && item.expand" style="margin-left: 16px;" :data="item.list" />
    </li>
  </ul>
</template>

<script>
import XRecyclerNode from './XRecyclerNode';

export default {
  name: 'XRecyclerItem',
  components: {
    XRecyclerNode
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style lang="less" scoped>
  .task-node {
    display: flex;
    .fix-icon {
      margin: 8px 6px;
      opacity: 0;
      &.mousein {
        opacity: 1;
      }
    }
    .node-primary {
      border-radius: 8px;
      padding: 0px 12px 0px 0px;
      display: flex;
      flex: 1;
      // align-items: center;
      &.selected {
        background-color: #ECF1FF;
      }
      &.mousein {
        background-color: #F5FAFF;
      }
      .node-content {
        flex: 1;
        margin-left: 12px;
        border-bottom: 0.5px solid #ccc;
        .node-top {
          height: 40px;
          display: flex;
          align-items: center;
          .title {
            flex: 1;
            font-size: 14px;
            color: #222;
          }
          .date {
            font-size: 14px;
            color: #3D87DA;
          }
        }
        .node-bottom {
          .sub-title {
            margin: 0;
            font-size: 13px;
            line-height: 18px;
            color: var(--vp-c-text-2);
            align-items: flex-start;
          }
          margin-bottom: 8px;
        }
      }
    }
  }
</style>