<template>
  <div class="tab-edit">
    <p style="margin-bottom: 12px;">{{ title }}</p>
    <Input class="title" v-model="formdata.name" :border="false" placeholder="请输入清单名称" @on-change="onChange" />
    <Poptip placement="right" :width="220" trigger="hover">
      <div class="space-between edit-cell hand">
        <span>图标</span>
        <span>
          <Image v-if="formdata.iconUrl" :src="formdata.iconUrl" width="30px" alt="icon" style="margin-right: 6px;"/>
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
      <template #content>
        <div style="text-align: center;">
          <Grid :col="6" :border="false" padding="5px 5px" style="margin-bottom: 12px;">
            <GridItem 
              style="text-align: center;" 
              v-for="icon in iconList" 
              :key="icon.code"
            >
              <Image :src="icon.url" width="20px" alt="icon" class="hand" @click="onIconClick(icon.url)" />
            </GridItem>
          </Grid>
          <Upload
            style="text-align: center;"
            action=""
            :format="['jpg','jpeg','png']"
            :before-upload="beforeUpolad">
            <a href="javascript:void(0)">本地上传</a>
          </Upload>
          <!-- <Poptip placement="bottom" :width="200">
            <a href="javascript:void(0)">在线搜索</a>
            <template #content>
              <div style="overflow-y: scroll;">
                <Input v-model="searchIconKey" placeholder="输入图标名称" clearable @on-enter="iconSearch" />
              </div>
              <Scroll :on-reach-bottom="onScrollBottom">
                <Grid :col="6" :border="false" padding="5px 5px" style="margin-bottom: 12px;">
                  <GridItem 
                    style="text-align: center;" 
                    v-for="icon in iconList" 
                    :key="icon.code"
                  >
                    <Image :src="icon.url" width="20px" alt="icon" class="hand" @click="onIconClick(icon.url)" />
                  </GridItem>
                </Grid>
              </Scroll>
            </template>
          </Poptip> -->
        </div>
      </template>
    </Poptip>
    <Poptip placement="right" :width="220" trigger="hover" class="fixed">
      <div class="space-between edit-cell hand">
        <span>上级清单</span>
        <span>
          <span style="font-szie: 12px; margin-right: 6px;">{{ formdata.parentProjectName || '不设置' }}</span>
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
      <template #content>
        <Tree 
          :data="projectList" 
          children-key="list" 
          @on-select-change="onTreeSelect"
        ></Tree>
      </template>
    </Poptip>
    <!-- <Cascader placement="right" :data="data" @on-change="handleChange" trigger="hover">
      <div class="space-between edit-cell hand">
        <span>上级清单</span>
        <span>
          <span style="font-szie: 12px; margin-right: 6px;">不设置</span>
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
    </Cascader> -->
    <Poptip placement="right" :width="310" trigger="hover">
      <div class="space-between edit-cell hand">
        <span>清单进度</span>
        <span>
          <span style="font-szie: 12px; margin-right: 6px;">{{ progressMap[formdata.progressMode] }}</span>
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
      <template #content>
        <RadioGroup v-model="formdata.progressMode" vertical @on-change="onChange">
          <Radio :label="0">
            <span>不设置</span>
          </Radio>
          <Radio :label="1">
            <span>自动设置</span>
            <span style="font-size: 9px; color: lightgray;">（进度=完成任务数/总任务数）</span>
          </Radio>
          <Radio :label="2">
            <span>手动设置</span>
            <span style="font-size: 9px; color: lightgray;">（拖动并调整清单进度）</span>
          </Radio>
        </RadioGroup>
        <div style="padding: 0 30px;" v-show="formdata.progressMode==2">
          <Slider v-model="formdata.progress" :tip-format="progressFormat" @on-change="onChange"></Slider>
        </div>
      </template>
    </Poptip>
  </div>
</template>

<script>
import { Input } from 'view-ui-plus';
import { ossUpload } from '@/common/api'

export default {
  components: { Input },
  props: {
    title: {
      type: String,
      default: '编辑'
    },
    modelValue: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      formdata: {},
      iconLoading: false,
      searchIconPage: 1,
      searchIconKey: '',
      searchIcons: [],
      progressMap: {
        0: '不设置',
        1: '自动设置',
        2: '手动设置'
      },
    }
  },
  mounted() {
    this.operateData();
  },
  computed: {
    iconList() {
      return this.$store.state.iconList;
    },
    projectList() {
      return this.$store.getters.normalProjectList;
    }
  },
  methods: {
    operateData() {
      this.formdata = Object.assign({}, this.modelValue);
      this.formdata.progressMode = this.formdata.progressMode || 0;
      // this.formdata = {
      //   name: this.modelValue.name,
      //   icon: this.modelValue.icon || 'https://www.yimutodo.com/images/icon/ic_project_default.png',
      //   parentProjectId: this.modelValue.parentProjectId,
      //   parentProjectName: this.modelValue.parentProjectName,
      //   progressMode: this.modelValue.progressMode || 0,
      //   progress: this.modelValue.progress
      // }
    },
    onChange() {
      this.$emit('update:modelValue', this.formdata);
    },
    onIconClick(iconUrl) {
      this.formdata.iconUrl = iconUrl;
      this.onChange();
    },
    removeKeyFromUrl(url, keyToRemove) {
      const urlObj = new URL(url);

      const params = new URLSearchParams(urlObj.search);

      params.delete(keyToRemove);

      urlObj.search = params.toString();
      return urlObj.toString();
    },
    beforeUpolad(file) {
      const extension = file?.type.split('/')[1];
      const userId = this.$store.state.user.userId
      const fileName = `${userId}${Date.now()}`
      const objectKey = `projectImage/${fileName}.${extension}`
      this.formdata.iconLocalUrl = null
      ossUpload(objectKey, file).then(res => {
        if (res) {
          this.formdata.iconLocalUrl = objectKey
          this.onIconClick(res.url)
        }
      });
      return false;
    },
    // iconSearch() {
    //   console.log(this.searchIconKey);
    //   this.searchIcons = [];
    // },
    // onScrollBottom() {
      
    // },
    onTreeSelect(list) {
      if (list && list.length > 0) {
        let node = list[0];
        if (this.modelValue.isEdit && this.modelValue.projectIdStr == node.projectIdStr) {
          return false;
        }
        this.formdata.parentProjectName = node.name;
        this.formdata.parentProjectIdStr = node.projectIdStr; 
        if (node.children && node.children.length > 0) {
          let model = node.children[node.children.length - 1];
          this.formdata.positionWeight = model.positionWeight - 1;
        }
        this.onChange();
      }
    },
    progressFormat(val) {
      return val + '%';
    }
  },
  watch: {
    modelValue: function () {
      this.operateData();
    }
  }
}
</script>

<style lang="less" scoped>

  .tab-edit {
    :deep(.ivu-poptip) {
      width: 100%;
    }
    :deep(.ivu-poptip-rel) {
      width: 100%;
    }
    .edit-cell {
      height: 100%;
      line-height: 44px;
      padding: 0px 10px;
      border-radius: 4px;
      &:hover {
        background-color: var(--vp-c-bg-alt);
      }
    }
    .title:deep(.ivu-input) {
      margin-bottom: 9px;
      border-radius: 10px;
      background-color: var(--vp-c-bg-alt);
      color: var(--vp-c-text-2);
      height: 48px;
      &:focus{
        color: var(--vp-c-text-1);
      }
    }
    .fixed:deep(.ivu-poptip-body-content) {
      height: 200px;
    }
  }

</style>