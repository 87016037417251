<template>
  <div>
    <CellGroup>
      <Space direction="vertical" style="width: 100%">
        <div class="noticeList-wrapper"
             v-for="(item, index) in noticeList"
             :key="index"
             @click="onCellClick(item)"
             >
          <template v-if="item.msg">
            <div>
              <p>{{ item.title }} <XIcon iconClass="user_member" style="margin-left: 13px" v-if="item.isVip"/></p>
              <Input
                  @on-change="changeEmail"
                  v-if="item.isInput"
                  v-model="item.value"
                  placeholder="请输入邮箱" />
              <div style="font-size: 12px" v-else>{{ item.msg }}</div>
            </div>
            <Icon
                :type="cellCheck(item) ? 'ios-checkmark-circle' : 'md-radio-button-off'"
                class="primary-color"
                style="font-size: 20px;"
            />
          </template>
        </div>
      </Space>
    </CellGroup>
  </div>
</template>

<script>

import {Message, Space} from "view-ui-plus";
import {userInfo} from "@/common/api";

const noticeList = [
  {
    mode:0,
    title:"应用提醒",
    key: 'appNotice',
    msg:"通知栏、弹框等方式进行提醒",
  },{
    mode:1,
    title:"微信提醒",
    key: 'noticeWechat',
    msg:"微信关注'一木宇宙'公众号进行提醒",
    isVip: true
  }, {
    mode:2,
    title:"邮箱提醒",
    key: 'emailAddress',
    msg:"",
    isVip: true,
    isInput: true
  },  
];

export default {
  components: {Space},
  props: {
    modelValue: {
      type: String,
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      noticeList: noticeList
    };
  },
  async mounted() {
    if (!this.userInfo.userId) {
      await this.loadInfo();
    }
    if (!this.noticeList[2].msg){
      this.noticeList[2].value = this.userInfo.email
      this.noticeList[2].msg = this.userInfo.email
    }
  },
  methods: {
    async loadInfo() {
      const res = await userInfo();
      if (res) {
        this.$store.commit("updateInfo", res);
      }
    },
    cellCheck(item) {
      return this.dataList.some(
          subItem => item.key == subItem.key
      );
    },
    changeEmail(){
      const index = this.dataList.findIndex(i=>i.key == 'emailAddress');
      let list = [...this.dataList];
      if (index != - 1) {
        list[index].value = this.noticeList[1].value
      }else{
        if (!this.isVip){
          Message.error('解锁会员后可使用');
          return
        }
        list.push({key:'emailAddress',value:this.noticeList[1].value});
      }
      const str = list.reduce((pre,item)=>{
        pre[item.key] = item.value;
        return pre;
      },{});
      this.$emit('update:modelValue', JSON.stringify(str));
    },
    onCellClick(item){
      const index = this.dataList.findIndex(i=>i.key == item.key);
      let list = [...this.dataList];
      if (index != - 1) {
        list.splice(index, 1);
      }else{
        if (!this.isVip && (item.mode == 1 || item.mode == 2)){
          Message.error('解锁会员后可使用');
          return
        }
        list.push({key:item.key,value:item.value||true});
      }
      const str = list.reduce((pre,item)=>{
        pre[item.key] = item.value;
        return pre;
      },{});
      this.$emit('update:modelValue', JSON.stringify(str));
    }
  },
  computed:{
    dataList(){
      let arr = [];
      if (this.modelValue){
        let obj = JSON.parse(this.modelValue);
        console.log("obj",obj)
        Object.keys(obj).forEach(key=>{
            if(obj[key] == true){
              arr.push({key,value:obj[key]})
            }
            if (key == 'emailAddress' && obj[key]){
              this.noticeList[2].value = obj[key]
              this.noticeList[2].msg = obj[key]
              arr.push({key,value:obj[key]})
            }
        })
      }
      console.log("dataList",arr)
      console.log("this.noticeList",this.noticeList)
      return arr;
    },
    userInfo() {
      return this.$store.state.user;
    },
    isVip() {
      let memberType = this.userInfo.memberType;
      switch (memberType) {
        case 'NORMAL':
          return false;
        case 'MONTH':
          return true;
        case 'YEAR':
          return true;
        case 'PERMANENT':
          return true;
        default:
          return false;
      }
    },
  },
}
</script>
<style lang="less" scoped>
.noticeList-wrapper {
  display: flex; 
  align-items: center; 
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding: 8px 16px;
  &:hover{
    background-color: #b9b9b92d;
  }
  :deep(.ivu-input) {
    border-color: #1b9aee;
    background-color: var(--vp-c-bg-alt);
    box-shadow: 0px 1px 12px 0px #00000011;
    color: var(--vp-c-text-1);
  }
}
</style>