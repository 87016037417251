<template>
  <div
    ref="taskNode"
    class="task-node hand"
    :style="data.completeTime > 0 || data.giveUp ? 'opacity: 0.3;' : ''"
    @click="onClick"
    @mouseenter="onMouseIn"
    @mouseleave="onMouseOut"
    @contextmenu.prevent.stop="onMoreClick"
  >
    <!-- <XIcon iconClass="menu" :class="{'move': true, 'fix-icon': true, 'mousein': mousein}" size="24" /> -->
    <div :class="{'node-primary': true, 'hand': true, 'mousein': mousein, 'selected': selected}">
      <div style="height: 40px; display: flex; align-items: center;">
        <XArrow :show="showArrow" :open="isOpen" @onArrowChanged="onArrowClick" />
        <XIcon v-if="data.taskType==0" :iconClass="checkClass" @click.stop="onCheckClick" :style="checkStyle" class="check" />
        <XIcon v-if="data.taskType==1" iconClass="file" />
        <div 
          v-if="data.taskType==2"
          style="display: flex;flex-direction: column;"
          @click.stop="updateClock(data)">
          <Icon 
            v-if="todayPercent(data) == 100" 
            type="md-checkmark" 
            :size="18" 
            style="color:#3D87DA">
          </Icon>
          <Icon 
            v-else-if="todayGiveUp(data)" 
            type="md-close" 
            size="20" 
            style="color:#999">
          </Icon>
          <Circle 
            v-else
            :percent="todayPercent(data)" 
            stroke-color="#3D87DA" 
            :size="18">
          </Circle>
        </div>
      </div>
      <div class="node-content">
        <div class="node-top">
          <Input
            :ref="('taskInput' + this.data.taskIdStr)"
            class="title"
            v-model="copyData.title"
            :border="false"
            placeholder="无标题"
            @on-focus="onTitleFocus"
            @on-blur="onTitleBlur"
            @on-enter="onInputEnter"
            @on-keydown.tab="onInputKeydown"
          />
          <!-- <Text class="title" ellipsis>{{ data.title }}</Text> -->
          <div style="display: flex; flex-direction: column; align-items:flex-end">
            <template
                v-if="data.taskType == 2"
            >
              <Space>
                <Text class="date" ellipsis>{{ `${todayNum(data)}/${data.habitDTO.habitDayNum}次` }}</Text>
                <Text class="date" ellipsis>{{ `已坚持${clockDateCount}天` }}</Text>
              </Space>
            </template>
            <template
                v-else
            >
              <Text class="date" :style="dateStyle" ellipsis>{{ showTime }}</Text>
            </template>


            <Space :size="3">
              <XIcon v-if="!detailMode && data.tagList && data.tagList.length > 0" size="12" iconClass="task_sign" />
              <XIcon v-if="!detailMode && data.addressIdStr" size="12" iconClass="task_location" />
              <XIcon v-if="!detailMode && showContent" size="12" iconClass="task_file" />
              <XIcon v-if="data.fields" size="12" iconClass="task_accessory" />
              <XIcon v-if="data.hasNotice" size="12" iconClass="task_alert" />
              <XIcon v-if="data.hasRepeat" size="12" iconClass="task_cyclic" />
            </Space>
          </div>
        </div>
        <Space class="node-bottom" direction="vertical" v-if="detailMode" :size="3">
          <Text v-if="showContent" class="sub-title" ellipsis>{{ showContent }}</Text>
          <Text v-if="data.addressIdStr" class="sub-title" ellipsis>
            <XIcon size="12" iconClass="task_location" />
            {{ showAddress }}
          </Text>
          <div v-if="data.tagList && data.tagList.length > 0" style="margin-left: 7px;">
            <Tag style="border-radius:13px;border:none;" v-for="tag in showTagList" :key="tag.tagIdStr" :color="showTagColor(tag.color) + '40'">
              <span :style="{ color: showTagColor(tag.color) }">{{ tag.tagName }}</span>
            </Tag>
          </div>
        </Space>
      </div>
    </div>
    <!-- <XIcon iconClass="more" :class="{'move': true, 'fix-icon': true, 'mousein': mousein}" size="24" /> -->
  </div>
</template>

<script>

import XArrow from './XArrow';
import '@/common/util';
import { colorFromValue, formatTime, timeExpire } from '@/common/util';
import {clockedAdd, clockedUpdate, taskUpdate} from '@/common/api';
import {dayjs} from "element-plus";

export default {
  name: 'XTaskNode',
  props: {
    data: {
      type: Object,
    },
    ignore: {
      type: Boolean,
      default: false
    }
  },
  inject: ['onMenuClick'],
  components: {
    XArrow
  },
  data() {
    return {
      mousein: false,

      copyData: this.data,
      originTitle: '',
    }
  },
  mounted() {
    if (this.selected) {
      let key = 'taskInput' + this.data.taskIdStr;
      if (this.$refs[key]) {
        this.$refs[key].focus({cursor: 'end'});
      }
    }
  },
  computed: {
    dateStyle() {
      if (this.data.completeTime > 0 || timeExpire(this.data.startTime, this.data.endTime)) {
        return 'color: #AAAAAA;';
      }
      return '';
    },
    selected() {
      return this.data.taskIdStr == this.$route.params.task;
    },
    checkStyle() {
      const colors = {
        0: 'var(--vp-c-text-1)',
        3: '#F12121',
        2: '#FA9300',
        1: '#5877FA'
      };
      let key = this.data.level || 0;
      let color = colors[key];
      return {color};
    },
    detailMode() {
      return this.$store.state.taskDetailMode;
    },
    showArrow() {
      return this.data.list && this.data.list.length > 0;
    },
    isOpen() {
      return this.data.expand != false;
      // let open = this.$store.state.openTask[this.data.id];
      // return open != null ? open : true;
    },
    checkClass() {
      if (this.data.giveUp) {
        return 'check_invalid';
      } else if (this.data.completeTime > 0) {
        return 'check_complete';
      }
      return 'check';
    },
    showContent() {
      if (!this.data.content) return null;
      let list = JSON.parse(this.data.content);
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item.spanList && item.spanList.length > 0) {
          for (let j = 0; j < item.spanList.length; j++) {
            const subItem = item.spanList[j];
            if (subItem.content && subItem.content.length > 0) {
              return subItem.content;
            }
          }
        }
      }
      return null;
    },
    timeExpired() {
      let nowTime = new Date().getTime();
      return (this.data.endTime > 0 && this.data.endTime < nowTime) ||
        (this.data.startTime > 0 && this.data.startTime < nowTime);
    },
    showTime() {
      return formatTime(this.data.startTime, this.data.endTime, this.data.completeTime == 0);
    },
    showAddress() {
      for (let i = 0; i < this.$store.state.addressList.length; i++) {
        const element = this.$store.state.addressList[i];
        if (element.addressIdStr == this.data.addressIdStr) {
          return element.poiAddress;
        }
      }
      return '';
    },
    showTagList() {
      let list = [];
      this.data.tagList.forEach(e1 => {
        this.$store.state.baseTagList.forEach(e2 => {
          if (e1 == e2.tagIdStr) {
            list.push(e2);
          }
        });
      });
      return list;
    },
    clockDateCount(){
      let array = [];
      if (this.data.taskType == 2 && this.data.clockedHistoryDTOList){
        array = this.data.clockedHistoryDTOList.filter(item=>{
          return item.complete;
        })
      }
      return array.length;
    },
  },
  methods: {
    onClick() {
      if (this.ignore) return;
      if (this.$route.params.project) {
        this.$router.push('/manager/work/' + this.$route.params.project + '/' + this.data.taskIdStr).then(() => {
          this.$store.commit('taskChanged', this.data);
        });
      } else {
        this.$store.commit('updateToastTask', this.data);
      }
    },
    onMouseIn() {
      this.mousein = true;
    },
    onMouseOut() {
      this.mousein = false;
    },
    onArrowClick(value) {
      // this.$store.commit('openTaskChanged', {[this.data.id]: value});
      let copyData = this.data;
      copyData.expand = value;
      taskUpdate(copyData);
      //TODO expand可以更新了 打开下面代码局部更新 保留任务的展开情况
      // this.$store.commit('updateListFlag', {
      //   key: 'update',
      //   value: copyData
      // });
    },
    onMoreClick(e) {
      this.onMenuClick(e, this.data);
    },
    onCheckClick() {
      let copyData = this.data;
      if (copyData.giveUp || copyData.completeTime > 0) {
        copyData.completeTime = 0;
        copyData.giveUp = false;
      } else {
        copyData.completeTime = Date.now();
        if (!copyData.originTime) {
          copyData.originTime = copyData.startTime;
        }
      }
      this.$store.commit('updateListFlag', {
        key: 'update',
        value: copyData
      });
    },
    showTagColor(value) {
      return value ? colorFromValue(value) : '#3D87DA';
    },
    onTitleFocus() {
      this.originTitle = this.copyData.title;
    },
    onTitleBlur() {
      if (this.originTitle != this.copyData.title) {
        taskUpdate(this.copyData);
      }
    },
    onInputEnter() {
      let key = 'taskInput' + this.data.taskIdStr;
      if (this.$refs[key]) {
        this.$refs[key].blur();
      }
    },
    async onInputKeydown(e) {
      let newKey = localStorage.getItem("newTaskKey") || 'Enter';
      if (e.key == newKey) {
        e.preventDefault();
        this.onInputEnter();
        this.onMenuClick('Tab', this.data);
      }
    },
    async updateClock(task, time, needPop){
      console.log("时间",time)
      if (!time){
        time = dayjs().valueOf()
      }
      //判断是否完成今日的打卡
      //没有完成  根据配置直接更新打卡，不用弹框 采用add方式
      let lastItem = this.todayLastItem(task, time);
      let clock = {
        "clockedMood": "",//打卡表情   cry哭  nervous紧张  normal正常  good好的 happy高兴
        "completeNum": 1,
        "clockedLog": "",
        "clockedDetail": "[{\"content\":\"1/1次\",\"time\":1694620651063}]",
        "giveUp": false,
        "updateTime": new Date().getTime(),
      }
      if (lastItem){
        clock.clockedHistoryId = lastItem.clockedHistoryId;
        clock.clockedHistoryIdStr = lastItem.clockedHistoryIdStr;
        clock.totalNum = lastItem.totalNum;
        clock.time = lastItem.time
        clock.completeNum = lastItem.completeNum
        clock.clockedMood = lastItem.clockedMood
        clock.clockedLog = lastItem.clockedLog
        clock.clockedDetail = lastItem.clockedDetail
      }else{
        clock.totalNum = task.habitDTO.habitDayNum;
        clock.time =  dayjs(time).valueOf();
      }

      clock.taskIdStr = task.taskIdStr;
      clock.taskId = task.taskId;
      if(this.todayPercent(task) != 100){
        //配置分为3种
        if (task.habitDTO.habitType == 0){
          if (lastItem){
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.completeNum + task.habitDTO.habitOnceNum
            if (clock.completeNum > clock.totalNum){
              clock.completeNum = clock.totalNum;
            }
            if (clock.completeNum == clock.totalNum){
              clock.complete = true;
            }else{
              clock.complete = false;
            }
            const array = JSON.parse(lastItem.clockedDetail)
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedUpdate(clock)
          }else{
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = task.habitDTO.habitOnceNum
            if (clock.completeNum > clock.totalNum){
              clock.completeNum = clock.totalNum;
            }
            if (clock.completeNum == clock.totalNum){
              clock.complete = true;
            }else{
              clock.complete = false;
            }
            const array = []
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedAdd(clock)
          }
        }else if (task.habitDTO.habitType == 1){
          this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
          this.$store.commit('updateShowDialog', true);
          return
        }else if (task.habitDTO.habitType == 2){
          if (lastItem){
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.totalNum;
            clock.complete = true;
            const array = JSON.parse(lastItem.clockedDetail)
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedUpdate(clock)
          }else{
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.totalNum;
            clock.complete = true;
            const array = []
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedAdd(clock)
          }
        }
        //1自动按照量打卡 每次都是add一个配置量的打卡
        //2手动指定量打卡  弹框输入量打卡  add
        //3全部完成 每次都是add或者update一个全部量的打卡

        if(task.habitDTO.autoShowLog){//弹框
          this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
          this.$store.commit('updateShowDialog', true);
        }
      }else{
        this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
        this.$store.commit('updateShowDialog', true);
        return
      }
      //完成打卡的  根据当前的最后一次打卡记录做更新操作 弹出弹框
      //更新列表里的这个数据，刷新页面
      let copyData = JSON.parse(JSON.stringify(task));
      this.$store.commit('updateListFlag', {
        key: 'update',
        value: copyData
      });
    },
    todayLastItem(task, time){
      let date = dayjs(time).format('YYYYMMDD');
      let array = [];
      let item = null;
      if (task.taskType == 2 && task.clockedHistoryDTOList) {
        array = task.clockedHistoryDTOList.filter(item => {
          return dayjs(item.time).format('YYYYMMDD') == date;
        });
        if (array.length > 0){
          item = array[array.length-1]
        }
      }
      return item;
    },
    todayNum(task){
      const date = dayjs().format('YYYYMMDD');
      let array = [];
      let completeNum = 0;
      if (task.taskType == 2 && task.clockedHistoryDTOList){
        array = task.clockedHistoryDTOList.filter(item=>{
          return dayjs(item.time).format('YYYYMMDD')== date;
        });
        completeNum = array.reduce((pre,item)=>{
          return pre + item.completeNum;
        },0)
      }
      return completeNum;
    },
    todayPercent(task){
      if (task.taskType  == 2 ){
        let percent = this.todayNum(task) * 100 / task.habitDTO.habitDayNum;
        if(percent>100){
          percent = 100
        }
        return percent;
      }else{
        return 0;
      }
    },
    todayGiveUp(task){
      const date = dayjs().format('YYYYMMDD');
      let array = [];
      if (task.taskType == 2 && task.clockedHistoryDTOList){
        array = task.clockedHistoryDTOList.filter(item=>{
          return dayjs(item.time).format('YYYYMMDD')== date;
        });
        return array.some(item=>{
          let records = []
          try {
            records = JSON.parse(item.clockedDetail);
          }catch (e){
            console.log(e)
          }
          if (records.length){
            const content = records[records.length-1].content;
            if (content =='放弃了打卡'){
              return true;
            }
          }
        })
      }
      return false;
    }
  },
  watch: {
    data(value) {
      this.copyData = value;
    }
  }
}
</script>

<style lang="less" scoped>
  .task-node {
    display: flex;
    margin: 0 10px;
    padding:0 10px;
    /* background-color: #acacac; */
    border-radius: 8px;
    :deep(.ivu-input) {
      background-color: transparent !important;
      height: 40px;
      border-radius: 8px;
      color:var(--vp-c-text-1)
    }
    &:hover {
      border-color: transparent;
    }
    .fix-icon {
      margin: 8px 6px;
      opacity: 0;
      &.mousein {
        opacity: 1;
      }
    }
    .node-primary {
      border-radius: 8px;
      padding: 0px 12px 0px 0px;
      display: flex;
      flex: 1;
      border: 0.5px dashed var(--vp-c-bg-node);
      // align-items: center;
      .check {
        &:hover {
          background-color: #9191918a;
          border-radius: 8px;
        }
      }
      &.selected {
        // background-color: #3D87DA14 !important;
        border-color: #1b9aee;
        background-color: var(--vp-c-bg-alt);
      }
      &.mousein {
        background-color: var(--vp-c-bg-alt);
      }
      .node-content {
        flex: 1;
        margin-left: 2px;
        .node-top {
          height: 40px;
          display: flex;
          align-items: center;
          .title {
            flex: 1;
            font-size: 14px;
            color: var(--vp-c-text-1);
          }
          .date {
            font-size: 10px;
            color: #3D87DA;
          }
        }
        .node-bottom {
          .sub-title {
            margin-left: 7px;
            font-size: 12px;
            line-height: 18px;
            color: var(--vp-c-text-2);
            align-items: flex-start;
          }
          margin-bottom: 8px;
        }
      }
    }
  }
</style>
