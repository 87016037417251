<template>
  <draggable
    class="dragArea"
    :disabled="data.parentTagIdStr"
    v-bind="dragOptions"
    tag="ul"
    group="tag"
    v-model="copyData.list"
    item-key="tagId"
    :move="onMove"
    @end="onEnd"
  >
    <template #item="{element}">
      <li v-if="copyData.list && copyData.expand" class="tag-item-li" >
        <XTagNode :data="element"/>
        <XTagItem style="margin-left: 16px;" :data="element" />
      </li>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import { } from '@/common/api'
import XTagNode from "@/components/XTagNode";

let moveData = {};

export default {
  name: 'XTagItem',
  components: { draggable, XTagNode },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      copyData: this.data
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'tagGroup',
        ghostClass: 'drag-ghost'
      }
    },
  },
  methods: {
    onMove(e) {
      moveData = e.draggedContext.element;
    },
    onEnd() {
      // let index = moveData.projectType == 1 ? 0 : 1;
      // let parent = this.$store.state.projectList[index];
      
      // let project = this.searchParent(parent, moveData);
      // projectUpdate(project).then(res => {
      //   if (res) {
      //     moveData.positionWeight = project.positionWeight;
      //   }
      // });
      console.log(moveData);
    },
    searchParent(parent, project) {
      let list = parent.list;
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element.projectIdStr == project.projectIdStr) {
          let newProject = Object.assign({}, project);
          if (i == 0) {
            if (list.length > 1) {
              newProject.positionWeight = list[1].positionWeight + 1;
            }
          } else if (i == list.length - 1) {
            newProject.positionWeight = list[this.data.list.length - 2].positionWeight - 1;
          } else {
            newProject.positionWeight = (list[i - 1].positionWeight + list[i + 1].positionWeight) / 2;
          }
          newProject.parentProjectIdStr = parent.projectIdStr;
          newProject.parentProjectName = parent.name;
          return newProject;
        } else {
          let newProject = this.searchParent(element, project);
          if (newProject) return newProject;
        }
      }
      return null;
    },
  },
  watch: {
    data() {
      this.copyData = this.data;
    }
  }
}
</script>

<style lang="less" scoped>
  // .drag-ghost {
    // border: 1px solid #4772Fa;
  // }
  .dragArea {
    padding-top: 5px;
  }
  .tag-item-li{
    background-color: var(--vp-c-bg);
    color: var(--vp-c-text-2);
    border-radius: 8px;
  }
</style>