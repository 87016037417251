<template>
  <div
    ref="taskNode"
    class="task-node hand"
    :style="data.completeTime > 0 ? 'opacity: 0.5;' : ''"
    @click="onClick"
    @mouseenter="onMouseIn"
    @mouseleave="onMouseOut"
    @contextmenu.prevent.stop="onMoreClick"
  >
    <span style="width: 24px"></span>
    <!-- <XIcon iconClass="menu" :class="{'move': true, 'fix-icon': true, 'mousein': mousein}" size="24" /> -->
    <div :class="{'node-primary': true, 'hand': true, 'mousein': mousein}">
      <div style="height: 40px; display: flex; align-items: center;">
        <XArrow :show="showArrow" :open="isOpen" @onArrowChanged="onArrowClick" />
        <XIcon v-if="data.taskType==0" :iconClass="checkClass" @click.stop="onCheckClick" :style="checkStyle" />
        <XIcon v-if="data.taskType==1" iconClass="file" />
        <Circle :percent="todayPercent(data)" stroke-color="#ff5733" v-if="data.taskType==2" :size="20"
                @click="updateClock(data)">
          <Icon v-if="todayPercent(data) == 100" type="ios-checkmark" size="20" style="color:#ff5733"></Icon>
          <Icon v-if="todayGiveUp(data)" type="ios-close" size="20" style="color:#999"></Icon>
        </Circle>
      </div>
      <div class="node-content">
        <div class="node-top">
          <Text class="title" ellipsis>{{ data.title }}</Text>
          <Space :size="3">
            <XIcon v-if="!detailMode && data.tagList && data.tagList.length > 0" size="16" iconClass="task_sign" />
            <XIcon v-if="!detailMode && data.addressIdStr" size="16" iconClass="task_location" />
            <XIcon v-if="!detailMode && showContent" size="16" iconClass="task_file" />
            <XIcon v-if="data.fields" size="16" iconClass="task_accessory" />
            <XIcon v-if="data.hasNotice" size="16" iconClass="task_alert" />
            <XIcon v-if="data.hasRepeat" size="16" iconClass="task_cyclic" />
            <Text class="date" :style="timeExpired ? 'color: gray;' : ''" ellipsis>{{ showTime }}</Text>
          </Space>
        </div>
        <Space class="node-bottom" direction="vertical" v-if="detailMode">
          <Text v-if="showContent" class="sub-title" ellipsis>{{ showContent }}</Text>
          <Text v-if="data.addressIdStr" class="sub-title" ellipsis>
            <XIcon size="16" iconClass="task_location" />
            {{ showAddress }}
          </Text>
          <div v-if="data.tagList && data.tagList.length > 0">
            <Tag style="border-radius:13px;border:none;" v-for="tag in showTagList" :key="tag.tagIdStr" :color="showTagColor(tag.color) + '40'">
              <span :style="{ color: showTagColor(tag.color) }">{{ tag.tagName }}</span>
            </Tag>
          </div>
        </Space>
      </div>
    </div>
    <span style="width: 24px"></span>
    <!-- <XIcon iconClass="more" :class="{'move': true, 'fix-icon': true, 'mousein': mousein}" size="24" /> -->
  </div>
</template>

<script>

import XArrow from './XArrow';
import '@/common/util';
import { colorFromValue, formatTime } from '@/common/util';
import {dayjs} from "element-plus";
import {clockedAdd, clockedUpdate} from "@/common/api";

export default {
  name: 'XTaskNode',
  props: {
    data: {
      type: Object,
    }
  },
  inject: ['onRecycleMenuClick'],
  components: {
    XArrow
  },
  data() {
    return {
      mousein: false,
    }
  },
  mounted() {
  },
  computed: {
    // selected() {
    //   return this.data.id === this.$store.state.currentProject.id;
    // },
    checkStyle() {
      const colors = {
        0: 'black',
        3: '#F12121',
        2: '#FA9300',
        1: '#5877FA'
      };
      let key = this.data.level || 0;
      let color = colors[key];
      return {color};
    },
    detailMode() {
      return true;
    },
    showArrow() {
      return this.data.list && this.data.list.length > 0;
    },
    isOpen() {
      let open = this.$store.state.openTask[this.data.id];
      return open != null ? open : true;
    },
    checkClass() {
      if (this.data.giveUp) {
        return 'check_invalid';
      } else if (this.data.completeTime > 0) {
        return 'check_complete';
      }
      return 'check';
    },
    showContent() {
      if (!this.data.content) return null;
      let list = JSON.parse(this.data.content);
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item.spanText && item.spanText.length > 0) {
          return item.spanText;
        }
      }
      return null;
    },
    timeExpired() {
      let nowTime = new Date().getTime();
      return this.data.endTime > 0 && this.data.endTime < nowTime;
    },
    showTime() {
      return formatTime(this.data.startTime, this.data.endTime);
    },
    showAddress() {
      for (let i = 0; i < this.$store.state.addressList.length; i++) {
        const element = this.$store.state.addressList[i];
        if (element.addressIdStr == this.data.addressIdStr) {
          return element.poiAddress;
        }
      }
      return '';
    },
    showTagList() {
      let list = [];
      this.data.tagList.forEach(e1 => {
        this.$store.state.baseTagList.forEach(e2 => {
          if (e1 == e2.tagIdStr) {
            list.push(e2);
          }
        });
      });
      return list;
    },
  },
  methods: {
    onClick() {
      this.$router.push('/manager/work/' + this.$route.params.project + '/' + this.data.taskIdStr).then(() => {
        this.$store.commit('taskChanged', this.data);
      });
    },
    onMouseIn() {
      this.mousein = true;
    },
    onMouseOut() {
      this.mousein = false;
    },
    onArrowClick(value) {
      this.$store.commit('openTaskChanged', {[this.data.id]: value});
    },
    onMoreClick(e) {
      this.onMenuClick(e, this.data);
    },
    onCheckClick() {
      let copyData = this.data;
      if (copyData.giveUp || copyData.completeTime > 0) {
        copyData.completeTime = 0;
        copyData.giveUp = false;
      } else {
        copyData.completeTime = Date.now();
        if (!copyData.originTime) {
          copyData.originTime = copyData.startTime;
        }
      }
      this.$store.commit('updateListFlag', {
        key: 'update',
        value: copyData
      });
    },
    showTagColor(value) {
      return colorFromValue(value);
    },
    async updateClock(task, time, needPop){
      console.log("时间",time)
      if (!time){
        time = dayjs().valueOf()
      }
      //判断是否完成今日的打卡
      //没有完成  根据配置直接更新打卡，不用弹框 采用add方式
      let lastItem = this.todayLastItem(task, time);
      let clock = {
        "clockedMood": "",//打卡表情   cry哭  nervous紧张  normal正常  good好的 happy高兴
        "completeNum": 1,
        "clockedLog": "",
        "clockedDetail": "[{\"content\":\"1/1次\",\"time\":1694620651063}]",
        "giveUp": false,
        "updateTime": new Date().getTime(),
      }
      if (lastItem){
        clock.clockedHistoryId = lastItem.clockedHistoryId;
        clock.clockedHistoryIdStr = lastItem.clockedHistoryIdStr;
        clock.totalNum = lastItem.totalNum;
        clock.time = lastItem.time
        clock.completeNum = lastItem.completeNum
        clock.clockedMood = lastItem.clockedMood
        clock.clockedLog = lastItem.clockedLog
        clock.clockedDetail = lastItem.clockedDetail
      }else{
        clock.totalNum = task.habitDTO.habitDayNum;
        clock.time =  dayjs(time).valueOf();
      }

      clock.taskIdStr = task.taskIdStr;
      clock.taskId = task.taskId;
      if(this.todayPercent(task) != 100){
        //配置分为3种
        if (task.habitDTO.habitType == 0){
          if (lastItem){
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.completeNum + task.habitDTO.habitOnceNum
            if (clock.completeNum > clock.totalNum){
              clock.completeNum = clock.totalNum;
            }
            if (clock.completeNum == clock.totalNum){
              clock.complete = true;
            }else{
              clock.complete = false;
            }
            const array = JSON.parse(lastItem.clockedDetail)
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedUpdate(clock)
          }else{
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = task.habitDTO.habitOnceNum
            if (clock.completeNum > clock.totalNum){
              clock.completeNum = clock.totalNum;
            }
            if (clock.completeNum == clock.totalNum){
              clock.complete = true;
            }else{
              clock.complete = false;
            }
            const array = []
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedAdd(clock)
          }
        }else if (task.habitDTO.habitType == 1){
          this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
          this.$store.commit('updateShowDialog', true);
          return
        }else if (task.habitDTO.habitType == 2){
          if (lastItem){
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.totalNum;
            clock.complete = true;
            const array = JSON.parse(lastItem.clockedDetail)
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedUpdate(clock)
          }else{
            if (needPop){
              this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
              this.$store.commit('updateShowDialog', true);
              return
            }
            clock.completeNum = clock.totalNum;
            clock.complete = true;
            const array = []
            array.push({content:`${clock.completeNum}/${clock.totalNum}${task.habitDTO.habitUnit}`,
              time:dayjs().valueOf()})
            clock.clockedDetail = JSON.stringify(array);
            await clockedAdd(clock)
          }
        }
        //1自动按照量打卡 每次都是add一个配置量的打卡
        //2手动指定量打卡  弹框输入量打卡  add
        //3全部完成 每次都是add或者update一个全部量的打卡

        if(task.habitDTO.autoShowLog){//弹框
          this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
          this.$store.commit('updateShowDialog', true);
        }
      }else{
        this.$store.commit('updateClockInfo', {task,clock,clockTime:time});
        this.$store.commit('updateShowDialog', true);
        return
      }
      //完成打卡的  根据当前的最后一次打卡记录做更新操作 弹出弹框
      //更新列表里的这个数据，刷新页面
      let copyData = JSON.parse(JSON.stringify(task));
      this.$store.commit('updateListFlag', {
        key: 'update',
        value: copyData
      });
    },
    todayLastItem(task, time){
      let date = dayjs(time).format('YYYYMMDD');
      let array = [];
      let item = null;
      if (task.taskType == 2 && task.clockedHistoryDTOList) {
        array = task.clockedHistoryDTOList.filter(item => {
          return dayjs(item.time).format('YYYYMMDD') == date;
        });
        if (array.length > 0){
          item = array[array.length-1]
        }
      }
      return item;
    },
    todayNum(task){
      const date = dayjs().format('YYYYMMDD');
      let array = [];
      let completeNum = 0;
      if (task.taskType == 2 && task.clockedHistoryDTOList){
        array = task.clockedHistoryDTOList.filter(item=>{
          return dayjs(item.time).format('YYYYMMDD')== date;
        });
        completeNum = array.reduce((pre,item)=>{
          return pre + item.completeNum;
        },0)
      }
      return completeNum;
    },
    todayPercent(task){
      if (task.taskType == 2 ){
        let percent = this.todayNum(task) * 100 / task.habitDTO.habitDayNum;
        if(percent>100){
          percent = 100
        }
        return percent;
      }else{
        return 0;
      }
    },
    todayGiveUp(task){
      const date = dayjs().format('YYYYMMDD');
      let array = [];
      if (task.taskType == 2 && task.clockedHistoryDTOList){
        array = task.clockedHistoryDTOList.filter(item=>{
          return dayjs(item.time).format('YYYYMMDD')== date;
        });
        return array.some(item=>{
          let records = []
          try {
            records = JSON.parse(item.clockedDetail);
          }catch (e){
            console.log(e)
          }
          if (records.length){
            const content = records[records.length-1].content;
            if (content =='放弃了打卡'){
              return true;
            }
          }
        })
      }
      return false;
    }
  }
}
</script>

<style lang="less" scoped>
  .task-node {
    display: flex;
    .fix-icon {
      margin: 8px 6px;
      opacity: 0;
      &.mousein {
        opacity: 1;
      }
    }
    .node-primary {
      border-radius: 8px;
      padding: 0px 12px 0px 0px;
      display: flex;
      flex: 1;
      // align-items: center;
      &.selected {
        background-color: #ECF1FF;
      }
      &.mousein {
        background-color: #F5FAFF;
      }
      .node-content {
        flex: 1;
        margin-left: 12px;
        .node-top {
          height: 40px;
          display: flex;
          align-items: center;
          .title {
            flex: 1;
            font-size: 14px;
            color: #222;
          }
          .date {
            font-size: 14px;
            color: #3D87DA;
          }
        }
        .node-bottom {
          .sub-title {
            margin: 0;
            font-size: 13px;
            line-height: 18px;
            color: var(--vp-c-text-2);
            align-items: flex-start;
          }
          margin-bottom: 8px;
        }
      }
    }
  }
</style>
