<template>
  <div>
    <CellGroup>
      <Cell
        v-for="(item, index) in dataList"
        :key="index"
        :title="item.msg"
        :icon="item.icon"
        @click="onCellClick(item)"
        >
        <template #icon>
          <Icon
            :type="cellCheck(item) ? 'ios-checkmark-circle' : 'md-radio-button-off'"
            class="primary-color"
            style="font-size: 20px;"
          />
        </template>
        <template #extra>
          <Icon v-if="item.self" type="ios-close" style="font-size: 24px;" @click.stop="onCellClear(item)"/>
        </template>
      </Cell>
    </CellGroup>
    <Button type="text" @click="onCustomClick">
      <span class="primary-color" >+&nbsp;&nbsp;{{type==2?'自定义频率':'自定义重复'}}</span>
    </Button>
    <Modal 
      :mask="true"
      draggable
      sticky
      scrollable
      :width="420"
      v-model="showModal"
      :loading="modalLoading"
      class="drag-modal"
      :title="type==2?'自定义频率':'自定义重复'"
      @on-ok="onModalOk">
      <div class="repeat-btn-group">
        <ButtonGroup shape="circle">
          <Button :type="customInfo.startMode==2?'primary':'default'" @click="customInfo.startMode=2" v-if="type != 2">按时间</Button>
          <Button :type="customInfo.startMode==2?'primary':'default'" @click="customInfo.startMode=2" v-else>按时间</Button>
          <Button :type="customInfo.startMode==1?'primary':'default'" @click="customInfo.startMode=1" v-if="type != 2">完成后</Button>
          <Button :type="customInfo.startMode==4?'primary':'default'" @click="customInfo.startMode=4" v-else>按天数</Button>
          <Button :type="customInfo.startMode==3?'primary':'default'" @click="customInfo.startMode=3">固定日</Button>
        </ButtonGroup>
        <p style="font-size: 12px; color: gray; margin: 12px 0;">{{ showTip }}</p>
        <RadioGroup v-if="customInfo.startMode!=3" v-model="customInfo.repeatMode">
          <Radio :label="1">天</Radio>
          <Radio :label="2">周</Radio>
          <Radio :label="3">月</Radio>
        </RadioGroup>
        <div v-if="customInfo.startMode!=3" style="display: flex; align-items: center; margin: 25px 0px; justify-content: space-between;">
          <span>每</span>
          <InputNumber style="width: 50px; margin: 0px 20px;" :max="365" :min="1" v-model="customInfo.interval" />
          <span>{{ unit }}</span>
        </div>
        <div
          v-if="((customInfo.startMode==2 && type == 2) || customInfo.startMode==1)&&customInfo.repeatMode==2"
          class="space-between"
          style="margin-bottom: 10px;"
        >
          <Tag
            style="width: 35px; text-align: center; margin-bottom: 4px;"
            v-for="(item, index) in ['一', '二', '三', '四', '五', '六', '日']"
            :key="index"
            checkable
            color="primary"
            :checked="(customInfo.weekdays || []).indexOf(index + 2) > -1"
            @click="onWeekTagClick(index + 2)"
          >
            {{ item }}
          </Tag>
        </div>
        <div
          v-if="((customInfo.startMode==2 && type == 2) || customInfo.startMode==1)&&customInfo.repeatMode==3"
          style="flex-wrap: wrap; margin-bottom: 10px;"
          class="space-between">
          <Tag
            style="width: 35px; text-align: center; margin-bottom: 4px;"
            v-for="index in 31"
            :key="index"
            checkable d
            color="primary"
            :checked="(customInfo.monthDays || []).indexOf(index + 1) > -1"
            @click="onMonthTagClick(index + 1)"
          >
            {{ index }}
          </Tag>
          <Tag v-for="index in 5" :key="index" style="width: 35px; opacity: 0;"></Tag>
        </div>
        <el-config-provider :locale="locale">
          <el-date-picker
            v-if="customInfo.startMode==3"
            style="margin: 12px;"
            v-model="customInfo.fixedDays"
            type="dates"
            placeholder="选择重复日期"
            value-format="x"
          />
        </el-config-provider>
        <div
          v-if="((customInfo.startMode==2 && type == 2) || customInfo.startMode==1)&&customInfo.repeatMode==3"
          style="width: 100%; margin: 6px;"
          class="space-between"
        >
          <span>每月最后一天</span>
          <Switch v-model="customInfo.monthLast" />
        </div>
        <div
          style="width: 100%; margin: 6px;"
          class="space-between"
        >
          <span>跳过法定节假日</span>
          <Switch v-model="customInfo.jumpHoliday" />
        </div>
        <div
          v-if="customInfo.startMode!=3&&customInfo.repeatMode!=2"
          style="width: 100%; margin: 6px;"
          class="space-between"
        >
          <span>跳过双休日</span>
          <Switch v-model="customInfo.jumpWeekend" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import 'element-plus/es/components/date-picker/style/css';
import { ElDatePicker, ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'dayjs/locale/zh-cn';

const repeatList = [{
  msg: "不重复",
  mode: 0,
}, {
    msg: "随时打卡",
    repeatMode: 15,
    monthDays: null,
    jumpWeekend: false,
    habitCycleNum: 0,
    repeatEndDate: 0,
    weekdays: null,
    yearMonth: 0,
    yearDay: 0,
    fixedDays: null,
    endMode: 3,
    ebbinghausInterval: 0,
    modeNumEndTime: null,
    monthLast: false,
    repeatEndTimes: 0,
    repeatEndHabitTimes: 1,
    startMode: 0,
    fixedLunarDays: null,
    self: false,
    interval: 1,
    jumpHoliday: false,
  },
  {
  endMode: 0,
  fixedDays: null,
  interval: 1,
  jumpHoliday: false,
  jumpWeekend: false,
  monthDays: null,
  monthLast: false,
  msg: "每天",
  repeatEndDate: 0,
  repeatEndTimes: 0,
  repeatMode: 1,
  self: false,
  startMode: 0,
  weekdays: null,
  yearDay: 0,
  yearMonth: 0
}, {
  endMode: 0,
  fixedDays: null,
  interval: 1,
  jumpHoliday: false,
  jumpWeekend: false,
  monthDays: null,
  monthLast: false,
  msg: "每周(周一)", // 周一是2，以此类推
  repeatEndDate: 0,
  repeatEndTimes: 0,
  repeatMode: 2,
  self: false,
  startMode: 0,
  weekdays: [2],
  yearDay: 0,
  yearMonth: 0
}, {
  endMode: 0,
  fixedDays: null,
  interval: 1,
  jumpHoliday: false,
  jumpWeekend: false,
  monthDays: [28],
  monthLast: false,
  msg: "每月(28日)",
  repeatEndDate: 0,
  repeatEndTimes: 0,
  repeatMode: 3,
  self: false,
  startMode: 0,
  weekdays: null,
  yearDay: 0,
  yearMonth: 0
}, {
  endMode: 0,
  fixedDays: null,
  interval: 1,
  jumpHoliday: false,
  jumpWeekend: false,
  monthDays: null,
  monthLast: false,
  msg: "每年(11月28日)",
  repeatEndDate: 0,
  repeatEndTimes: 0,
  repeatMode: 4,
  self: false,
  startMode: 0,
  weekdays: null,
  yearDay: 28,
  yearMonth: 11
}, {
  endMode: 0,
  fixedDays: null,
  interval: 1,
  jumpHoliday: false,
  jumpWeekend: false,
  monthDays: null,
  monthLast: false,
  msg: "工作日(周一至周五)",
  repeatEndDate: 0,
  repeatEndTimes: 0,
  repeatMode: 5,
  self: false,
  startMode: 0,
  weekdays: null,
  yearDay: 0,
  yearMonth: 0
}, {
  endMode: 0,
  fixedDays: null,
  interval: 1,
  jumpHoliday: false,
  jumpWeekend: false,
  monthDays: null,
  monthLast: false,
  msg: "法定工作日",
  repeatEndDate: 0,
  repeatEndTimes: 0,
  repeatMode: 6,
  self: false,
  startMode: 0,
  weekdays: null,
  yearDay: 0,
  yearMonth: 0
}, {
  endMode: 0,
  fixedDays: null,
  interval: 1,
  jumpHoliday: false,
  jumpWeekend: false,
  monthDays: null,
  monthLast: false,
  msg: "法定节假日",
  repeatEndDate: 0,
  repeatEndTimes: 0,
  repeatMode: 7,
  self: false,
  startMode: 0,
  weekdays: null,
  yearDay: 0,
  yearMonth: 0
}];

export default {
  components: {
    ElDatePicker,
    ElConfigProvider,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    startTime: {
      type: Number,
      default: 0
    },
    type: {
      type: Number,//2是习惯 其他不是
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      locale: zhCn,

      showModal: false,
      modalLoading: false,
      customList: [],
      dataList: [],

      customInfo: {}
    };
  },
  mounted() {
    this.packageList();
  },
  computed: {
    unit() {
      let unit = '';
      if (this.customInfo.repeatMode == 1) {
        unit = '天';
      } else if (this.customInfo.repeatMode == 2) {
        unit = '周';
      } else if (this.customInfo.repeatMode == 3) {
        unit = '月';
      }
      return unit;
    },
    showTip() {
      if (this.customInfo.startMode == 1) {
        let count = this.customInfo.interval;
        return this.type == 2? '根据设置的规则执行习惯':`完成后，自动创建完成日期${count}${this.unit}后的任务`;
      } else if (this.customInfo.startMode == 2) {
        return '完成后，根据设置的规则重复创建任务';
      } else if (this.customInfo.startMode == 3){
        return this.type == 2? '根据设置的固定日期执行习惯':'完成后，根据设置的规则重复创建任务';
      }else if (this.customInfo.startMode == 4) {
        return '一定周期内执行指定天数的习惯';
      }else{
        return this.type == 2? '根据设置的规则执行习惯':'完成后，根据设置的规则重复创建任务';
      }
    },
  },
  methods: {
    packageList() {
      if (this.startTime > 0) {
        let dayNames = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
        let time = new Date(this.startTime);
        let month = time.getMonth() + 1;
        let day = time.getDate();
        let weekDay = time.getDay();
        let r0 = repeatList[3];
        r0.weekdays = [weekDay + 1];
        r0.msg = `每周(${dayNames[weekDay]})`;
        let r1 = repeatList[4];
        r1.monthDays = [day];
        r1.msg = `每月(${day}日)`;
        let r2 = repeatList[5];
        r2.yearMonth = month;
        r2.yearDay = day;
        r2.msg = `每年${month}月${day}日`;
      }
      this.dataList = [...repeatList];
      if (this.type == 2){
        this.dataList = this.dataList.filter(item=>item.mode!=0);
      }else{
        this.dataList = this.dataList.filter(item=>item.repeatMode!=15);
      }
      if (this.modelValue.self) {
        this.dataList = [...this.dataList, this.modelValue];
      }
      if (Object.keys(this.modelValue).length == 0 || !this.modelValue.self) {
        this.customInfo = {
          endMode: 0,
          // fixedDays: new Date().setHours(0, 0, 0, 0),
          interval: 1,
          jumpHoliday: false,
          jumpWeekend: false,
          monthDays: null,
          monthLast: false,
          msg: "",
          repeatEndDate: 0,
          repeatEndTimes: 0,
          repeatMode: 1,
          self: true,
          startMode: 2,
          weekdays: null,
          yearDay: 0,
          yearMonth: 0
        };
      } else {
        this.customInfo = Object.assign({}, this.modelValue);
      }
    },
    showTitle(item) {
      if (item.mode == 0) {
        return '无提醒';
      } else if (item.mode == 2) {
        if (item.day == 0) {
          if (item.hour == 0 && item.minute == 0) {
            return '准时提醒';
          } else {
            return '当天' + `${item.hour}`.padStart(2, '0') + ':' + `${item.minute}`.padStart(2, '0');
          }
        } else {
          return '提前' + (item.day == 7 ? '一周' : `${item.day}天`) + `${item.hour}`.padStart(2, '0') + ':' + `${item.minute}`.padStart(2, '0');
        }
      } else if (item.mode == 1) {
        if (item.day == 0 && item.hour == 0 && item.minute == 0) {
          return '准时提醒';
        } else {
          let day = item.day == 0 ? '' : `${item.day}天`;
          let hour = item.hour == 0 ? '' : `${item.hour}小时`;
          let minute = item.minute == 0 ? '' : `${item.minute}分钟`;
          return '提前' + day + hour + minute;
        }
      }
    },
    cellCheck(item) {
      if (item.repeatMode == 0 && Object.keys(this.modelValue).length == 0) {
        return true;
      }
      let subItem = this.modelValue;
      let monthDaysEqual = item.monthDays == subItem.monthDays;
      if (!monthDaysEqual) {
        let a = item.monthDays || [];
        let b = subItem.monthDays || [];
        monthDaysEqual = a.join('') == b.join('');
      }
      return item.endMode == subItem.endMode &&
        item.jumpHoliday == subItem.jumpHoliday &&
        item.jumpWeekend == subItem.jumpWeekend &&
        monthDaysEqual &&
        item.monthLast == subItem.monthLast &&
        item.repeatEndDate == subItem.repeatEndDate &&
        item.repeatEndTimes == subItem.repeatEndTimes &&
        item.repeatMode == subItem.repeatMode &&
        item.startMode == subItem.startMode &&
        item.weekdays == subItem.weekdays &&
        item.yearDay == subItem.yearDay &&
        item.yearMonth == subItem.yearMonth;
    },
    onCustomClick() {
      this.showModal = true;
    },
    onCellClick(item) {
      console.log("zzzz",item)
      this.$emit('update:modelValue', item.mode == 0 ? {} : item);
    },
    onCellClear(item) {
      console.log(item);
      this.$emit('update:modelValue', {});

      // let index = this.dataList.indexOf(item);
      // this.dataList = this.dataList.splice(index, 1);
      // index = this.modelValue.indexOf(item);
      // if (index > -1) {
      //   let list = [...this.modelValue];
      //   list.splice(index, 1);
      //   this.$emit('update:modelValue', list);
      // }
    },
    onWeekTagClick(tag) {
      if (this.customInfo.weekdays) {
        if (this.customInfo.weekdays.length > 0 && this.customInfo.weekdays.indexOf(tag) > -1) {
          this.customInfo.weekdays.splice(this.customInfo.weekdays.indexOf(tag), 1);
        } else {
          this.customInfo.weekdays.push(tag);
          this.customInfo.weekdays = this.customInfo.weekdays.sort((a, b) => a - b);
        }
      } else {
        this.customInfo.weekdays = [tag];
      }
    },
    onMonthTagClick(tag) {
      console.log(tag);
      if (this.customInfo.monthDays) {
        if (this.customInfo.monthDays.length > 0 && this.customInfo.monthDays.indexOf(tag) > -1) {
          this.customInfo.monthDays.splice(this.customInfo.monthDays.indexOf(tag), 1);
        } else {
          this.customInfo.monthDays.push(tag);
          this.customInfo.monthDays = this.customInfo.monthDays.sort((a, b) => a - b);
        }
      } else {
        this.customInfo.monthDays = [tag];
      }
    },
    onModalOk() {
      let info = this.customInfo;
      let count = this.customInfo.interval > 1 ? `${this.customInfo.interval}` : '';
      if (info.startMode == 1) {
        info.msg = `完成后每${count}${this.unit}`;
      } else if (info.startMode == 2) {
        if (info.repeatMode == 1) {
          info.msg = `每${count}天`;
        } else if (info.repeatMode == 2) {
          if (info.weekdays && info.weekdays.length > 0) {
            let showList = info.weekdays.map(item => item - 1);
            info.msg = `每${count}周(${showList.join(',')})`;
          } else {
            return;
          }
        } else if (info.repeatMode == 3) {
          if (info.monthDays && info.monthDays.length > 0) {
            let showList = info.monthDays.map(item => item - 1);
            info.msg = `每${count}月(${showList.join(',')})`;
          } else {
            return;
          }
        }
      } else if (info.startMode == 4) {
        info.msg = `每${count}${this.unit}`;
      }else if (info.startMode == 3) {
        info.msg = '自选日期';
      }
      this.$emit('update:modelValue', this.customInfo);
    },
  },
  watch: {
    modelValue() {
      this.packageList();
    },
    startTime() {
      this.packageList();
    }
  }
}
</script>

<style lang="less" scoped>
  :deep(.ivu-tag:not(.ivu-tag-checked)) {
    background-color: rgba(201, 201, 201, 0.308) !important;
  }
  .drag-modal{
    .repeat-btn-group{
      display: flex; 
      align-items: center; 
      margin-top: 25px; 
      flex-direction: column;
      :deep(.ivu-btn-default) {
        background-color: var(--vp-c-bg);
        color: var(--vp-c-text-1);
      }
      :deep(.ivu-radio-inner) {
        background-color: var(--vp-c-bg);
      }
    }
  }
</style>
