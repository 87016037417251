<template>
  <List class="calendar-menu" :split="false">
    <ListItem 
      size="small"
      v-for="value, key in data" 
      :key="key" 
      :class="{'menu-item': true, 'selected': key==selected}"
      @click="onItemClick(key)">
      <XIcon :icon-class="key" style="margin-right: 8px; color: #9C9C9C;" />{{ value }}
    </ListItem>
  </List>
</template>

<script>
  /// 日历左侧菜单
  export default {
    data() {
      return {
        data: {
					'all': '所有',
					// 'man': '分配给我'
				},
				selected: 'all',
      }
    },
    methods: {
      onItemClick(target) {
        this.selected = target;
      }
    }
  }
</script>

<style lang="less">
  .calendar-menu {
    overflow: scroll;
    background-color: var(--vp-c-bg);
    color: var(--vp-c-text-1);
    flex: 1;
    padding: 16px 4px 16px 10px;
    li.menu-item {
      padding: 12px 8px;
      border-radius: 8px;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        margin-right: 8px;
      }
      &.selected,&:hover {
        background-color: var(--vp-c-bg-alt);
      }
    }
  }
</style>