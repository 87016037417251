<template>
  <div class="view">
    <div class="header">
      <Space direction="vertical">
        <span style="font-size: 22px">四象限</span>
      </Space>
      <span style="flex: 1"></span>
      <!-- <Space>
        <Switch size="small" v-model="showComplete" @on-change="onSwitchChange" />
        <span style="font-size: 14px;">显示已完成</span>
        <XIcon iconClass="more" />
      </Space> -->
      <Space>
        <XIcon
          icon-class="content"
          style="font-size: 14px"
          @click="openFiltraModal"
        />
        <Dropdown trigger="click" placement="bottom-end">
          <XIcon icon-class="sort" style="font-size: 16px;" />
          <template #list>
            <DropdownMenu class="dropdown-list" >
              <DropdownItem
                v-for="item in sortList"
                :key="item.id"
                :selected="sortId == item.id"
                @click="onSortClick(item)"
              >
                {{ item.title }}
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
        <Dropdown trigger="click" placement="bottom-end">
          <!-- <XIcon icon-class="more-col" style="font-size: 16px" /> -->
          <Icon type="md-more" size="22" class="more-icon"/>
          <template #list>
            <DropdownMenu class="dropdown-list" >
              <DropdownItem @click="onMoreClick">
                {{ showComplete ? "隐藏已完成" : "显示已完成" }}
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
      </Space>
    </div>
    <div class="quadrant">
      <div v-for="item in dataList" :key="item" class="item">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <Text :style="'color:' + item.color">{{ item.name }}</Text>
          <Space>
            <Dropdown trigger="click">
              <Icon type="md-add" style="font-size: 16px;cursor: pointer;"
              @click="addTask(item.level)" />
              <!-- <XIcon
                icon-class="add"
                style="font-size: 16px"
                @click="addTask(item.level)"
              /> -->
            </Dropdown>
          </Space>
        </div>
        <ul style="flex: 1; overflow-y: scroll">
          <XTaskGroup
            class="content"
            v-for="(item, index) in item.list"
            :key="item + index"
            :data="item"
          />
        </ul>
      </div>
    </div>
    <Modal
      v-if="showModal"
      class="task-detail-modal"
      title=" "
      :mask="true"
      :width="520"
      draggable
      sticky
      scrollable
      v-model="showModal"
      @on-ok="onModalOk"
    >
      <XToastTaskDetail :task="task" />
    </Modal>
    <Modal
      :mask="true"
      :width="440"
      v-model="showFiltra"
      @on-ok="onFiltraModalOk"
      draggable
      sticky
      scrollable
      title="任务筛选"
    >
      <XOptionModal
        :showFiltra="showFiltra"
        :data="filtraData"
        ref="filterRef"
      />
    </Modal>
  </div>
</template>

<script>
import {
  userInfo,
  taskList,
  taskUpdate,
  taskAdd,
  projectList,
  projectCount,
} from "@/common/api";
import XTaskGroup from "@/components/XTaskGroup";
import XToastTaskDetail from "@/components/XToastTaskDetail";
import { Space } from "view-ui-plus";
import XOptionModal from "@/components/XOptionModal.vue";

export default {
  components: {
    XOptionModal,
    Space,
    XTaskGroup,
    XToastTaskDetail,
  },
  data() {
    return {
      showFiltra: false,
      filtraData: {
        checkTypeVal: 1, //筛选类型 1.所有 2.指派给我 3.自定义
        type: [], //
        todo: [],
        date: [],
        tag: [],
        member: [],
      },
      task: {},
      showModal: false,
      showComplete: localStorage.getItem("showQuadrantComplete") || false,
      totalList: [],
      dataList: [],
      sortId: 0,
      sortList: [
        {
          title: "默认排序",
          id: 0,
        },
        {
          title: "清单排序",
          id: 1,
        },
        {
          title: "开始时间排序",
          id: 2,
        },
        {
          title: "截至时间排序",
          id: 3,
        },
        {
          title: "优先级排序",
          id: 4,
        },
        {
          title: "标签排序",
          id: 5,
        },
        {
          title: "地点排序",
          id: 6,
        },
      ],
    };
  },
  provide() {
    return {
      onMenuClick: () => {},
      onGroupMenuEvent: () => {},
    };
  },
  async mounted() {
    this.loadData(() => {});
    if (this.$store.state.baseProjectList?.length === 0) {
      const projectCountList = await this.getProjectCount();
      projectList().then((res) => {
        if (res) {
          res.map((item1) => {
            projectCountList.map((item2) => {
              if (item1.projectIdStr === item2.projectId.toString()) {
                item1.executingTaskCount = item2.executingTaskCount || 0;
                item1.completedTaskCount = item2.completedTaskCount || 0;
              }
            });
          });
          this.$store.commit("updateBaseProjects", res);
        }
      });
    }
  },
  methods: {
    async getProjectCount() {
      const res = await projectCount();
      return res;
    },
    openFiltraModal() {
      this.filtraData = JSON.parse(
        localStorage.getItem("quadrantManagerFiltraData") ??
          '{"checkTypeVal":1,"type":[],"todo":[],"date":[],"tag":[],"member":[]}'
      );
      this.showFiltra = true;
    },
    onFiltraModalOk() {
      this.filtraData = this.$refs.filterRef.filter;
      localStorage.setItem(
        "quadrantManagerFiltraData",
        JSON.stringify(this.filtraData)
      );
      this.packageData();
    },
    formatData(list) {
      let showList = [];
      list.forEach((item) => {
        if (item.key == "top") {
          showList.push(item);
          if (this.sortId == 2) {
            item.list = item.list.sort((a, b) => b.startTime - a.startTime);
          } else if (this.sortId == 3) {
            item.list = item.list.sort((a, b) => b.endTime - a.endTime);
          } else {
            item.list = item.list.sort(
              (a, b) => b.positionWeight - a.positionWeight
            );
          }
        } else if (item.key == "normal") {
          if (this.sortId == 0) {
            showList.push(item);
            item.list = item.list.sort(
              (a, b) => b.positionWeight - a.positionWeight
            );
          } else if (this.sortId == 1) {
            // if (this.isAllProject || this.isDateProject) {
            let obj = {};
            item.list.forEach((task) => {
              let projectId = task.projectIdStr;
              let subGroup = obj[projectId];
              if (!subGroup) {
                let cProject = this.$store.state.baseProjectList.find(
                  (subProject) => subProject.projectIdStr == projectId
                );
                subGroup = {
                  name: cProject ? cProject.name : "",
                  icon: cProject ? cProject.iconUrl : "",
                  key: "project",
                  projectId,
                  list: [task],
                };
                obj[projectId] = subGroup;
              } else {
                subGroup.list.push(task);
              }
            });
            let pList = Object.values(obj);
            pList.forEach((element) => {
              element.list = element.list.sort(
                (a, b) => b.positionWeight - a.positionWeight
              );
              showList.push(element);
            });
            // } else {
            //   this.groupList.forEach(group => {
            //     let subGroup = {
            //       key: 'group',
            //       ...group,
            //       list: []
            //     }
            //     item.list.forEach(task => {
            //       if (task.groupIdStr == group.groupIdStr || (task.groupIdStr == null && group.projectIdStr == group.groupIdStr) ) {
            //         subGroup.list.push(task);
            //       }
            //     });
            //     showList.push(subGroup);
            //   });
            // }
          } else if (this.sortId == 2 || this.sortId == 3) {
            let date = new Date().setHours(0, 0, 0, 0);
            let lastDate = date - 1000 * 60 * 60 * 24;
            let beforeGroup = {
              key: "date",
              time: lastDate,
              name: "今天以前",
              list: [],
            };
            let todayGroup = {
              key: "date",
              time: new Date().setHours(0, 0, 0, 0),
              name: "今天",
              list: [],
            };
            let otherGroup = {};
            let nodateGroup = {
              key: "date",
              time: 0,
              name: "没有日期",
              list: [],
            };
            item.list.forEach((task) => {
              let originTime = this.sortId == 2 ? task.startTime : task.endTime;
              if (task.startTime > 0 || task.endTime > 0) {
                const todayTime = new Date().setHours(0, 0, 0, 0);
                if (originTime < todayTime) {
                  beforeGroup.list.push(task);
                } else {
                  const startTime = new Date(originTime).setHours(0, 0, 0, 0);
                  if (startTime == todayTime) {
                    todayGroup.list.push(task);
                  } else {
                    const timeKey = new Date(originTime).format("MM月dd日");
                    let realGroup = otherGroup[timeKey];
                    if (realGroup) {
                      realGroup.list.push(task);
                    } else {
                      realGroup = {
                        key: "date",
                        time: new Date(originTime).setHours(0, 0, 0, 0),
                        name: timeKey,
                        list: [task],
                      };
                      otherGroup[timeKey] = realGroup;
                    }
                  }
                }
              } else {
                nodateGroup.list.push(task);
              }
            });
            let sortList = Object.values(otherGroup);
            sortList.sort((a, b) => a.time - b.time);
            let allList = [beforeGroup, todayGroup, ...sortList, nodateGroup];
            allList.forEach((element) => {
              if (element.list.length > 0) {
                if (this.sortId == 2) {
                  element.list = element.list.sort(
                    (a, b) => a.startTime - b.startTime
                  );
                } else {
                  element.list = element.list.sort((a, b) => {
                    let aTime = a.endTime > 0 ? a.endTime : a.startTime;
                    let bTime = b.endTime > 0 ? b.endTime : b.startTime;
                    return aTime - bTime;
                  });
                }
                showList.push(element);
              }
            });
          } else if (this.sortId == 4) {
            let highGroup = {
              name: "高优先级",
              key: "level",
              level: 3,
              list: [],
            };
            let middleGroup = {
              name: "中优先级",
              key: "level",
              level: 2,
              list: [],
            };
            let lowGroup = {
              name: "低优先级",
              key: "level",
              level: 1,
              list: [],
            };
            let noGroup = {
              name: "无优先级",
              key: "level",
              level: 0,
              list: [],
            };
            item.list.forEach((task) => {
              if (task.level == 0) {
                noGroup.list.push(task);
              } else if (task.level == 1) {
                lowGroup.list.push(task);
              } else if (task.level == 2) {
                middleGroup.list.push(task);
              } else if (task.level == 3) {
                highGroup.list.push(task);
              }
            });
            let allList = [highGroup, middleGroup, lowGroup, noGroup];
            allList.forEach((element) => {
              if (element.list.length > 0) {
                showList.push(element);
              }
            });
          } else if (this.sortId == 5) {
            let otherGroup = {};
            let noGroup = {
              name: "无标签",
              key: "tag",
              list: [],
            };
            item.list.forEach((task) => {
              if (task.tagList && task.tagList.length > 0) {
                let tagId = task.tagList[0];
                let realGroup = otherGroup[tagId];
                if (realGroup) {
                  realGroup.list.push(task);
                } else {
                  let tag = this.$store.state.baseTagList.find(
                    (item) => item.tagIdStr == tagId
                  );
                  if (tag) {
                    realGroup = {
                      key: "tag",
                      color: tag.color,
                      tagId: tag.tagIdStr,
                      name: tag.tagName,
                      list: [task],
                    };
                    otherGroup[tagId] = realGroup;
                  }
                }
              } else {
                noGroup.list.push(task);
              }
            });
            let allList = [...Object.values(otherGroup), noGroup];
            allList.forEach((element) => {
              if (element.list.length > 0) {
                showList.push(element);
              }
            });
          } else if (this.sortId == 6) {
            let otherGroup = {};
            let noGroup = {
              key: "address",
              name: "无地点",
              list: [],
            };
            item.list.forEach((task) => {
              if (task.addressIdStr) {
                let realGroup = otherGroup[task.addressIdStr];
                if (realGroup) {
                  realGroup.list.push(task);
                } else {
                  let address = this.$store.state.addressList.find(
                    (item) => item.addressIdStr == item.addressIdStr
                  );
                  if (address) {
                    realGroup = {
                      key: "address",
                      addressId: address.addressIdStr,
                      name: address.poiAddress,
                      list: [task],
                    };
                    otherGroup[task.addressIdStr] = realGroup;
                  } else {
                    noGroup.list.push(task);
                  }
                  otherGroup[task.addressIdStr] = realGroup;
                }
              } else {
                noGroup.list.push(task);
              }
            });
            let allList = [...Object.values(otherGroup), noGroup];
            allList.forEach((element) => {
              if (element.list.length > 0) {
                showList.push(element);
              }
            });
          }
        } else if (item.key == "complete") {
          if (this.showComplete) {
            showList.push(item);
            item.list = item.list.sort(
              (a, b) => b.completeTime - a.completeTime
            );
          }
        }
      });
      return showList;
    },
    onSwitchChange() {
      this.packageData();
    },
    onMoreClick() {
      this.showComplete = !this.showComplete;
      localStorage.setItem("showQuadrantComplete", this.showComplete);
      this.packageData();
    },
    loadData(callback) {
      userInfo().then((info) => {
        if (info && info.userId) {
          let projectId = "-2" + this.$store.state.user.userId;
          taskList(projectId).then((res) => {
            if (res) {
              this.totalList = res;
              this.filtraData = JSON.parse(
                localStorage.getItem("quadrantManagerFiltraData") ??
                  '{"checkTypeVal":1,"type":[],"todo":[],"date":[],"tag":[],"member":[]}'
              );
              this.packageData();
              callback && callback();
            }
          });
        }
      });
    },
    packageData() {
      let level3Group = {
        name: "重要且紧急",
        level: 3,
        color: "#F12121",
        list: [],
      };
      let level2Group = {
        name: "重要不紧急",
        level: 2,
        color: "#FA9300",
        list: [],
      };
      let level1Group = {
        name: "紧急不重要",
        level: 1,
        color: "#5877FA",
        list: [],
      };
      let level0Group = {
        name: "不重要不紧急",
        level: 0,
        color: "var(--vp-c-text-1);",
        list: [],
      };
      this.totalList.forEach((element) => {
        if (element.level == 3) {
          level3Group.list.push(element);
        } else if (element.level == 2) {
          level2Group.list.push(element);
        } else if (element.level == 1) {
          level1Group.list.push(element);
        } else {
          level0Group.list.push(element);
        }
      });

      level3Group.list = this.packageItem(level3Group.list);
      level2Group.list = this.packageItem(level2Group.list);
      level1Group.list = this.packageItem(level1Group.list);
      level0Group.list = this.packageItem(level0Group.list);

      const level3Normal = level3Group.list.find(
        (item) => item.key == "normal"
      );
      if (level3Normal) {
        level3Normal.level = 3;
      } else {
        level3Group.list.unshift({
          name: "默认",
          key: "normal",
          level: 3,
          list: [],
        });
      }
      const level2Normal = level2Group.list.find(
        (item) => item.key == "normal"
      );
      if (level2Normal) {
        level2Normal.level = 2;
      } else {
        level2Group.list.unshift({
          name: "默认",
          key: "normal",
          level: 2,
          list: [],
        });
      }
      const level1Normal = level1Group.list.find(
        (item) => item.key == "normal"
      );
      if (level1Normal) {
        level1Normal.level = 1;
      } else {
        level1Group.list.unshift({
          name: "默认",
          key: "normal",
          level: 1,
          list: [],
        });
      }
      const level0Normal = level0Group.list.find(
        (item) => item.key == "normal"
      );
      if (level0Normal) {
        level0Normal.level = 0;
      } else {
        level0Group.list.unshift({
          name: "默认",
          key: "normal",
          level: 0,
          list: [],
        });
      }
      level0Group.list = this.formatData(level0Group.list);
      level1Group.list = this.formatData(level1Group.list);
      level2Group.list = this.formatData(level2Group.list);
      level3Group.list = this.formatData(level3Group.list);
      this.dataList = [level3Group, level2Group, level1Group, level0Group];
    },
    packageItem(list) {
      let topGroup = {
        name: "置顶",
        color: "#3D87DA",
        key: "top",
        list: [],
      };
      let normalGroup = {
        name: "默认",
        key: "normal",
        list: [],
      };
      let completeGroup = {
        name: "已完成&已放弃",
        key: "complete",
        list: [],
      };

      list.forEach((element) => {
        if (this.filtraData.checkTypeVal !== 1) {
          if (this.filtraData.checkTypeVal === 2) {
            if (element?.executors !== null) {
              if (!element.executors.includes(this.$store.state.user.userId)) {
                return false;
              }
            } else {
              return false;
            }
          }

          if (this.filtraData.checkTypeVal === 3) {
            // 类型过滤
            if (this.filtraData.type.length) {
              if (element?.taskType !== null) {
                if (!this.filtraData.type.includes(element.taskType)) {
                  return false;
                }
              } else {
                return false;
              }
            }

            // 清单过滤
            if (this.filtraData.todo.length) {
              if (element?.projectIdStr !== null) {
                if (!this.filtraData.todo.includes(element.projectIdStr)) {
                  return false;
                }
              } else {
                return false;
              }
            }

            // 时间过滤
            if (this.filtraData.date.length) {
              if (!element.startTime && !element.endTime) {
                return false;
              } else {
                this.filtraData.date[0] >= element.startTime &&
                  this.filtraData.date[1] <= element.endTime;
              }
            }

            // 标签过滤
            if (this.filtraData.tag.length) {
              if (element?.tagIdStrList) {
                if (
                  !this.filtraData.tag.some((item) => {
                    return element.tagIdStrList.includes(item);
                  })
                ) {
                  return false;
                }
              } else {
                return false;
              }
            }

            // 成员过滤
            if (this.filtraData.member.length) {
              if (element?.executors !== null) {
                if (
                  !this.filtraData.member.some((item) => {
                    return element.executors.includes(item);
                  })
                ) {
                  return false;
                }
              } else {
                return false;
              }
            }
          }
        }

        element.list = [];
        element.expand = true;
        if (element.completeTime > 0 || element.giveUp) {
          completeGroup.list.push(element);
        } else if (element.isTop) {
          topGroup.list.push(element);
        } else {
          normalGroup.list.push(element);
        }
      });
      topGroup.list = this.packageTree(null, topGroup.list);
      normalGroup.list = this.packageTree(null, normalGroup.list);
      completeGroup.list = this.packageTree(null, completeGroup.list);
      let groupList = [];
      if (topGroup.list.length > 0) {
        groupList.push(topGroup);
      }
      if (normalGroup.list.length > 0) {
        groupList.push(normalGroup);
      }
      if (this.showComplete) {
        groupList.push(completeGroup);
      }
      return groupList;
    },
    packageTree(id, list, name) {
      let children = [];
      for (let i = 0; i < list.length; i++) {
        let element = list[i];
        if (element == null) continue;
        if (element.parentTaskIdStr == id) {
          element.parentTaskName = name;
          let inList = this.packageTree(element.taskIdStr, list, element.title);
          element.list = inList.sort(
            (a, b) => b.positionWeight - a.positionWeight
          );
          children.push(element);
          delete list[i];
        }
      }
      if (id == null) {
        list.forEach((element) => {
          if (element != null) {
            children.push(element);
          }
        });
        children = children.sort((a, b) => b.positionWeight - a.positionWeight);
      }
      return children;
    },
    onModalOk() {
      this.loadData();
    },
    // 象限添加任务,默认添加到收集箱,如果在首页有选中的项目,默认加在选中的项目
    async addTask(level) {
      let taskData = {
        giveUp: false,
        completeTime: 0,
        title: "",
        level: level,
        positionWeight: 20,
        projectIdStr: this.project.projectIdStr,
        taskType: 0,
        tagList: [],
        systemType: "PC",
      };
      let taskId = await taskAdd(taskData);
      this.loadData(() => {
        this.task = this.totalList.find((item) => item.taskId == taskId);
        this.showModal = true;
      });
    },
    onSortClick(item) {
      this.sortId = item.id;
      this.packageData();
    },
  },
  watch: {
    "$store.state.listFlag"(n) {
      if (n.key == "update") {
        let task = n.value;
        taskUpdate(task).then((res) => {
          if (res) {
            if (res) this.loadData();
            if (!task.giveUp && task.completeTime > 0) {
              this.$Message.success("恭喜，任务已完成");
            }
          }
        });
      }
    },
    "$store.state.toastTask"(task) {
      if (task) {
        this.task = task;
        this.showModal = true;
      }
    },
  },
  computed: {
    menuList() {
      let list = [
        {
          key: "edit",
          title: "编辑",
        },
        {
          key: "group",
          title: "分组",
        },
        {
          key: "sort",
          title: "排序",
        },
      ];
      return list;
    },
    project() {
      if (this.$store.state.currentProject.projectIdStr) {
        return this.$store.state.currentProject;
      } else {
        const p = this.$store.state.baseProjectList.find(
          (item) => item.name == "收集箱"
        );
        return p || this.$store.state.baseProjectList[0];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.view {
  height: 100%;
  width: 100%;
  background-color: var(--vp-c-bg-alt);
  color: var(--vp-c-text-1);
  .header {
    padding: 20px;
    padding-bottom: 0px;
    display: flex;
  }
  .quadrant {
    height: calc(100% - 56px);
    width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-wrap: wrap;
    padding-right: 20px;
    padding-bottom: 10px;
    &::-webkit-scrollbar {
      display: inherit !important; /* Chrome Safari */
    }
    .item {
      //margin: 20px 0px 30px 20px;
      margin: 20px 0px 0px 20px;
      border-radius: 12px;
      background-color: var(--vp-c-bg);
      color: var(--vp-c-text-1);
      padding: 10px;
      box-shadow: var(--vp-shadow-6);
      width: calc(50% - 20px);
      height: calc(50% - 20px);
      box-sizing: border-box;
      padding: 16px;
      display: flex;
      flex-direction: column;
      .content {
        overflow-y: scroll;
        flex: 1;
      }
    }
  }
}
</style>
