import axios from 'axios'
import { Message, Notice } from 'view-ui-plus';
import OSS from 'ali-oss'
import md5 from 'js-md5';
import router from '@/router'
import store from '@/store'

let isElectron = process.env.IS_ELECTRON;

let conf = {
  // 开发环境参数配置
  'development': {
    // api地址前缀，目前是相对的根目录，使用/proxy的值，配合 config.js 的target值，解决跨域问题
    baseUrl: isElectron ? '/api/v' : '/proxy/api/v',
    // baseUrl: 'https://yimutodo.com/api/v',
    // 图片地址
    imageUrl: '',
    // vux路由方式
    routerMode: 'hash'
  },
  // 生产环境参数配置
  'production':{
    // api地址前缀
    baseUrl: '/api/v',
    // 图片地址
    imageUrl: '',
    // vux路由方式
    routerMode: 'hash'
  }
};

let baseUrl = conf[process.env.NODE_ENV].baseUrl;
// let routerMode = conf[process.env.NODE_ENV].routerMode;
// let baseImgPath = conf[process.env.NODE_ENV].baseImgPath;

//允许跨域携带cookie信息
axios.defaults.withCredentials = true;
//30秒请求过期
axios.defaults.timeout = 30 * 1000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let client;
let userId; // 用户id

/**
 * 退出登录
 * @returns
 */
export const logout = () => request('/logout');

/**
 * 登录
 * @param {*} userName
 * @param {*} password
 * @param {*} type
 * @returns
 */
export const login = (userName, password, type='EMAIL') => request('/login', {
  userName,
  password: md5(password),
  type
}, 'post');

/**
 * 获取验证码
 * @param {*} email
 * @param {*} type
 * @returns
 */
export const captcha = (email, type = 0) => {
  let map = {
    0: 'USER_BIND_EMAIL', // 绑定邮箱
    1: 'USER_RESET_PASSWORD_BY_EMAIL', // 重置密码
    2: 'USER_UNBIND_EMAIL' // 解绑邮箱
  }
  let templateCode = map[type] || '';
  return operate('NOTICE_EMAIL_VERIFY_CODE_SEND', {email, templateCode});
};

/**
 * 注册
 * @param {*} userName
 * @param {*} password
 * @param {*} type
 * @returns
 */
export const register = (userName, password, type='EMAIL') => request('/register', {
  userName,
  password: md5(password),
  type
}, 'post');

/**
 * 用户注销
 * @param {*} captcha
 * @returns
 */
export const userDestroy = (captcha) => operate('USER_DESTROY', {
  captcha
});

/**
 * 用户信息更新
 * @param {*} nickName
 * @param {*} avatar
 * @returns
 */
export const modifyInfo = (nickName, avatar) => operate('USER_UPDATE_INFO', {
  nickName,
  avatar
});

/**
 * 用户信息更新
 * @param {*} nickName
 * @param {*} avatar
 * @returns
 */
export const modifyConfigInfo = (type, value) => operate('USER_UPDATE_CONFIG_INFO', {
  "method": "USER_UPDATE_CONFIG_INFO",
  type,//NOTICE_EMAIL_SWITCH：邮箱提醒开关；NOTICE_WECHAT_SWITCH：微信提醒开关
  value:value+""//注意为字符串，因为是通用配置
});

/**
 * 修改密码
 * @param {*} oldPassword
 * @param {*} newPassword
 * @returns
 */
export const modifyPassword = (oldPassword, newPassword) => operate('USER_UPDATE_PASSWORD', {
  oldPassword: md5(oldPassword),
  newPassword: md5(newPassword)
});

/**
 * 重设密码
 * @param {*} emial
 * @param {*} password
 * @param {*} verifyCode
 * @returns
 */
export const resetPassword = (emial, password, verifyCode) => operate('USER_RESET_PASSWORD_BY_EMAIL', {
  emial,
  password: md5(password),
  verifyCode
}, '/public');

/**
 * 绑定邮箱
 * @param {*} emial
 * @param {*} password
 * @param {*} verifyCode
 * @returns
 */
export const bindEmail = (emial, password, verifyCode) => operate('USER_BIND_EMAIL', {
  emial,
  password: md5(password),
  verifyCode
});

/**
 * 邮箱解绑
 * @param {*} verifyCode
 * @returns
 */
export const unbindEmail = (verifyCode) => operate('USER_UNBIND_EMAIL', { verifyCode });

/**
 * 第三方解绑
 * @param {*} type QQ/WECHAT/WEIBO
 * @param {*} openId
 * @returns
 */
export const unbindThird = (type, openId) => operate('USER_UNBIND_OPEN_INFO', {
  type,
  openId
});

/**
 * 日历信息
 * @param {*} year
 * @returns
 */
export const calendarInfo = (year) => operate('APP_CONFIG_GET_CALENDAR', { year });

/**
 * 获取oss信息
 * @returns
 */
export const ossInfo = async () => {
  const data = await operate('APP_CONFIG_GET_ALIYUN_OSS', { used: 'NORMAL' });
  if (data) {
    client = new OSS({
      region: 'oss-cn-shanghai',
      accessKeyId: data.accessKeyId,
      accessKeySecret: data.accessKeySecret,
      stsToken: data.securityToken,
      bucket: 'yimutodo'
    });
  }
};

/**
 * 图标库
 * @returns
 */
export const iconList = () => operate('APP_CONFIG_GET_ICONS');

/**
 * 获取所有项目列表
 * @returns
 */
export const projectList = async () => {
  let list = await operate('TODO_PROJECT_PAGE');
  if (list) {
    for (let i = 0; i < list.length; i++) {
      const element = list[i];
      if (element.iconUrl && element.iconUrl.indexOf('http') < 0) {
        element.iconUrl = await ossViewUrl(element.iconUrl);
      }
    }
    return list;
  }
  return null;
};

/**
 * 新增项目
 * @param {*} project
 * @returns
 */
export const projectAdd = (project) => operate('TODO_PROJECT_ADD', project);

/**
 * 更新项目
 * @param {*} project
 * @returns
 */
export const projectUpdate = (project) => operate('TODO_PROJECT_UPDATE', project);

/**
 * 删除项目
 * @param {*} project
 * @returns
 */
export const projectDelete = (project) => operate('TODO_PROJECT_DELETE', project);

/**
 * 主动退出项目
 * @param {*} projectId
 * @returns
 */
export const projectQuit = (projectId) => operate('TODO_PROJECT_QUIT', {projectId});

/**
 * 分组列表
 * @param {*} projectId
 * @returns
 */
export const groupList = (projectId) => operate('TODO_TASK_GROUP_PAGE', {projectId});

/**
 * 新增分组
 * @param {*} group
 * @returns
 */
export const groupAdd = (group) => operate('TODO_TASK_GROUP_ADD_V2', group);

/**
 * 修改分组
 * @param {*} group
 * @returns
 */
export const groupUpdate = (group) => operate('TODO_TASK_GROUP_UPDATE', group);

/**
 * 删除分组
 * @param {*} group
 * @returns
 */
export const groupDelete = (groupId) => operate('TODO_TASK_GROUP_DELETE', {groupId});

/**
 * 新增任务
 * @param {*} address
 * @returns
 */
export const taskAdd = (task) => {
  let noticeInfo = {};
  if (task.noticeInfo){
    noticeInfo = JSON.parse(task.noticeInfo)
  }
  if (!noticeInfo.appNotice){
    noticeInfo.appNotice = false;
  }
  if (!noticeInfo.emailAddress){
    noticeInfo.emailAddress = "";
  }
  if (!noticeInfo.noticeWechat){
    noticeInfo.noticeWechat = false;
  }
  task.noticeInfo = JSON.stringify(noticeInfo)
  return operate('TODO_TASK_ADD_V2', task);
}


/**
 * 修改任务
 * @param {*} task
 * @returns
 */
export const taskUpdate = async (task) => {
  let res = await operate('TODO_TASK_UPDATE', task);
  if (res) await superTaskUpdate(task);
  return res;
};

/**
 * 更新父任务,子任务全部完成之后完成父任务
 * @param {*} task 
 */
const superTaskUpdate = async (task) => {
  if (task.completeTime == 0 || !task.parentTaskIdStr) return;
  let autoComplete = localStorage.getItem('autoComplete');
  if (autoComplete == '1') {
    let list = await taskList(task.projectId);
    let superTask = {};
    let needComplete = true;
    list.forEach(element => {
      if (task.parentTaskIdStr == element.taskIdStr) {
        superTask = element;
      } else if (task.parentTaskIdStr == element.parentTaskIdStr) {
        if (!element.giveUp && element.completeTime == 0) {
          needComplete = false;
        }
      }
    });

    if (Object.keys(superTask).length > 0 && superTask.taskType == 0 && superTask.completeTime == 0 && !superTask.giveUp && needComplete) {
      superTask.completeTime = Date.now();
      await taskUpdate(superTask);
    }
  }
}

/**
 * 删除任务
 * @param {*} taskId
 * @param {*} completely 彻底删除
 * @returns
 */
export const taskDelete = (taskIdStr, completely = false) => operate('TODO_TASK_DELETE', {taskIdStr, completely});

/**
 * 新增习惯
 * @param {*} habit
 * @returns
 */
export const habitAdd = (habit) => operate('TODO_TASK_HABIT_ADD_V2', habit);

/**
 * 修改习惯
 * @param {*} habit
 * @returns
 */
export const habitUpdate = (habit) => operate('TODO_TASK_HABIT_UPDATE', habit);

/**
 * 删除习惯
 * @param {*} taskId
 * @returns
 */
export const habitDelete = (taskIdStr) => operate('TODO_TASK_HABIT_DELETE', {taskIdStr});

/**
 * 新增习惯打卡
 * @param {*} clocked
 * @returns
 */
export const clockedAdd = (clocked) => operate('TODO_TASK_CLOCKED_HISTORY_ADD_V2', clocked);

/**
 * 修改习惯打卡
 * @param {*} clocked
 * @returns
 */
export const clockedUpdate = (clocked) => operate('TODO_TASK_CLOCKED_HISTORY_UPDATE', clocked);

/**
 * 删除习惯打卡
 * @param {*} clockedHistoryIdStr
 * @returns
 */
export const clockedDelete = (clockedHistoryIdStr) => operate('TODO_TASK_CLOCKED_HISTORY_DELETE', {clockedHistoryIdStr});


/**
 * 分页获取项目下所有任务
 * @returns
 */
export const taskList = async (projectId) => {
  let page = 1;
  let list = [];
  do {
    let subList = await operate('TODO_TASK_PAGE', {projectId, page, pageSize: 200});
    if (subList && subList.length > 0) {
      list = list.concat(subList);
    }
    if (subList && subList.length > 0) {
      page++;
    } else {
      break;
    }
  } while (page);
  return list;
};



/**
 * 根据id获取任务详情
 * @param {*} taskId
 * @returns
 */
export const taskDetail = (taskId) => operate('TODO_TASK_GET_BY_ID', {id: taskId});

/**
 * 查询清单统计
 * @param {*} taskId
 * @returns
 */
export const projectCount = (projectId) => operate('TODO_PROJECT_STATISTIC', {projectId});

/**
 * 新增地址
 * @param {*} address
 * @returns
 */
export const addressAdd = (address) => operate('TODO_ADDRESS_ADD_V2', address);

/**
 * 修改地址
 * @param {*} address
 * @returns
 */
export const addressUpdate = (address) => operate('TODO_ADDRESS_UPDATE', address);

/**
 * 删除地址
 * @param {*} addressId
 * @returns
 */
export const addressDelete = (addressIdStr) => operate('TODO_ADDRESS_DELETE', {addressIdStr});

/**
 * 地址列表
 * @returns
 */
export const addressList = async () => {
  let page = 1;
  let list = [];
  do {
    let subList = await operate('TODO_ADDRESS_PAGE', {page, pageSize: 200});
    if (subList && subList.length > 0) {
      list = list.concat(subList);
    }
    if (subList && subList.length == 200)  {
      page++;
    } else {
      break;
    }
  } while (page);
  return list;
};

/**
 * 新增标签
 * @param {*} tag
 * @returns
 */
 export const tagAdd = (tag) => operate('TODO_TAG_ADD_V2', tag);

 /**
  * 修改标签
  * @param {*} tag
  * @returns
  */
 export const tagUpdate = (tag) => operate('TODO_TAG_UPDATE', tag);

 /**
  * 删除标签
  * @param {*} tagId
  * @returns
  */
 export const tagDelete = (tagId) => operate('TODO_TAG_DELETE', {tagId});

 /**
  * 标签列表
  * @returns
  */
 export const tagList = async () => {
   let page = 1;
   let list = [];
   do {
     let subList = await operate('TODO_TAG_PAGE', {page, pageSize: 200});
     if (subList && subList.length > 0) {
       list = list.concat(subList);
     }
     if (subList && subList.length == 200)  {
       page++;
     } else {
       break;
     }
   } while (page);
   return list;
 };

 /**
 * 查询项目下的成员信息
 * @param {*} habit
 * @returns
 */
export const getMember = (member) => operate('TODO_PROJECT_GET_MEMBER_BY_PROJECT_IDS', member);

/**
 * 查询提醒
 * @param {*} taskId
 * @returns
 */
export const noticeDetail = (taskId) => operate('TODO_TASK_NOTICE_GET', {taskId});

/**
 * 新增提醒
 * @param {*} notice
 * @returns
 */
export const noticeAdd = (notice) => operate('TODO_TASK_NOTICE_ADD', notice);

/**
 * 更新提醒
 * @param {*} notice
 * @returns
 */
export const noticeUpdate = (notice) => operate('TODO_TASK_NOTICE_UPDATE', notice);

/**
 * 删除提醒
 * @param {*} taskNoticeId
 * @returns
 */
export const noticeDelete = (taskNoticeIdStr) => operate('TODO_TASK_NOTICE_DELETE', {taskNoticeIdStr});

/// 提醒timer
let _noticeTimer;
/**
 * 将要提醒的事项
 */
export const noticeArrive = async () => {
  const notices = await operate('TODO_TASK_NOTICE_RECENT_QUERY');
  if (notices) {
    if (_noticeTimer) {
      clearTimeout(_noticeTimer);
      _noticeTimer = null;
    }
    if (notices.length > 0) {
      let notice = notices[0];
      notices.forEach(element => {
        if (element.noticeTime < notice.noticeTime) {
          notice = element;
        }
      });
      let nowTime = new Date().getTime();
      let duration = notice.noticeTime - nowTime;
      const ONE_DAY = 24 * 60 * 60 * 1000;
      if (duration < 0 || duration >= ONE_DAY) return;
      _noticeTimer = setTimeout(() => {
        console.log("zzzz开始")
        Notice.open({
          title: '提醒 ⏰',
          duration: 0,
          render: h => {
            return h('a', {
              style: {
                color: 'black'
              },
              onClick: async () => {
                const task = await taskDetail(notice.taskIdStr);
                router.push('/manager/work/' + task.projectIdStr + '/' + task.taskIdStr).then(() => {
                  store.commit('taskChanged', task);
                });
              }
            }, notice.title)
          }
        });
        if (window.Notification && Notification.permission === "granted"){

          const sysNotice = new Notification("提醒 ⏰", {
            icon: "/app.ico",
            body: notice.title,
          });

          sysNotice.onclick = async (event) => {
            event.preventDefault();
            const task = await taskDetail(notice.taskIdStr);
            router.push('/manager/work/' + task.projectIdStr + '/' + task.taskIdStr).then(() => {
              store.commit('taskChanged', task);
            });
          };
          
        }
        channelEvent('notice', {text:notice.title, id:notice.taskIdStr});
      }, duration);
    }
  }
}
/**
 * 客户端获取版本信息
 * @param {*} taskId
 * @returns
 */
export const getPCClientVersion = (key) => operate('APP_CONFIG_GET_LIST_BY_KEY', {key});
/**
 * 查询重复
 * @param {*} taskId
 * @returns
 */
 export const repeatDetail = (taskId) => operate('TODO_TASK_REPEAT_GET', {taskId});

 /**
  * 新增重复
  * @param {*} repeat
  * @returns
  */
 export const repeatAdd = (repeat) => operate('TODO_TASK_REPEAT_ADD', repeat);

 /**
  * 更新重复
  * @param {*} repeat
  * @returns
  */
 export const repeatUpdate = (repeat) => operate('TODO_TASK_REPEAT_UPDATE', repeat);

 /**
  * 删除重复
  * @param {*} taskRepeatId
  * @returns
  */
 export const repeatDelete = (taskRepeatIdStr) => operate('TODO_TASK_REPEAT_DELETE', {taskRepeatIdStr});

/**
 * 获取用户信息
 * @returns
 */
export const userInfo = async () => {
  const userInfo = await request('/atop/user/info');
  if (userInfo) {
    userId = userInfo.userId;
    if (userInfo.avatar && userInfo.avatar.indexOf('http') == -1) {
      userInfo.avatarUrl = await ossViewUrl(userInfo.avatar);
    } else {
      userInfo.avatarUrl = userInfo.avatar;
    }
    channelEvent('login');
    return userInfo;
  }
  return null;
};

/**
 * 获取用户配置信息
 * @returns
 */
export const userConifgInfo = () => operate('USER_GET_CONFIG_INFO', {})

/**
 * 获取用户id
 * @returns
 */
export const getUserId = () => userId;

/**
 * 微信通过code获取token
 * @param {*} code 前端调用有跨域问题
 * @returns
 */
export const wxAccessToken = (code) => request('https://api.weixin.qq.com/sns/oauth2/access_token', {
  appid: 'wxf569443760ff031e',
  secret: '22ad7ff6050e3ec6afdbe9b869c08749',
  code,
  grant_type: 'authorization_code'
});

/**
 * 第三方登录
 * @param {*} authCode
 * @param {*} type QQ、WECHAT、WEIBO
 * @returns
 */
export const loginThird = (authCode, type) => request('/atop/login/auth', {
  authCode,
  type
}, 'post');

/**
 * 通用操作接口
 * @param {*} method
 * @param {*} params
 * @param {*} path
 * @returns
 */
export const operate = (method, params, path='') => request('/atop' + path, {
  method,
  ...params,
}, 'post');

/**
 * 文件上传阿里云
 * @param {*} file
 * @returns
 */
export const ossUpload = async (fileName, file) => {
  if (!client) {
    await ossInfo();
  }
  return new Promise((res) => {
    client.multipartUpload(fileName, file).then(inRes => {
      if (inRes && inRes.res && inRes.res.status == 200) {
        ossViewUrl(fileName).then(url => {
          res({
            name: fileName,
            url: url + '&yimufilename=' + fileName
          });
        });
      } else {
        res(null);
        showErrorMessage();
      }
    }).catch(error => {
      res(null);
      console.log(error);
      showErrorMessage();
    });
  });
}

// 文件完整url
export const ossViewUrl = async (fileName) => {
  if (!client) {
    await ossInfo();
  }
  const response = {
    'content-disposition': `attachment; filename=${encodeURIComponent(fileName)}`
  }
  const url = client.signatureUrl(fileName, { response });
  return url;
}

// 文件下载
export const ossDownload = (data) => {
  console.log(data);
}

// 阿里云文件删除
export const ossDelete = async (fileName) => {
  if (!client) {
    await ossInfo();
  }
  return await client.delete(fileName);
}

// 阿里云使用记录
export const ossHistory = (fileName, isAdd=true) => operate('USER_STORE_INCR', {
  fileKey: fileName,
  incr: isAdd
});

 /**
  * 回收站项目列表
  * @returns
  */
export const recycleProjectList = async () => {
  let page = 1;
  let list = [];
  do {
    let subList = await operate('RECYCLE_PAGE_PROJECT', {page, pageSize: 200});
    if (subList && subList.length > 0) {
      list = list.concat(subList);
    }
    if (subList && subList.length == 200)  {
      page++;
    } else {
      break;
    }
  } while (page);
  return list;
};

 /**
  * 回收站任务列表
  * @returns
  */
export const recycleTaskList = async (projectId) => {
  let page = 1;
  let list = [];
  do {
    let subList = await operate('RECYCLE_PAGE_TASK', {projectId, page, pageSize: 200});
    if (subList && subList.length > 0) {
      list = list.concat(subList);
    }
    if (subList && subList.length == 200)  {
      page++;
    } else {
      break;
    }
  } while (page);
  return list;
};

/**
 * 清空回收站
 * @param {*} projectId
 * @returns
 */
export const recycleTaskClean = (projectId) => operate('RECYCLE_EMPTY_TASK', { projectId });

 /**
  * 日历贴纸列表
  * @returns
  */
export const stickerList = async () => {
  let page = 1;
  let list = [];
  do {
    let subList = await operate('TODO_STICKER_PAGE', {page, pageSize: 200});
    if (subList && subList.length > 0) {
      list = list.concat(subList);
    }
    if (subList && subList.length == 200)  {
      page++;
    } else {
      break;
    }
  } while (page);
  return list;
};

/**
 * 新增专注记录
 */
export const absorbedAdd = (absorbed) => operate('TODO_ABSORBED_ADD_V2', absorbed);

/**
 * 是否是用户信息接口
 */
const withoutJudge = (url, params) => {
  return url !== '/atop/user/info'
    && params['method'] !== 'APP_CONFIG_GET_ALIYUN_OSS'
    && params['method'] !== 'TODO_TASK_NOTICE_RECENT_QUERY';
}

/**
 * 发送网络请求
 * @param url
 * @param params_
 * @param method
 * @returns {Promise<>}
 */
export const request = (url, params=null, method='get', header=null) => {

  let requestUrl = url;
  if (requestUrl.indexOf('http') == -1) {
    requestUrl = baseUrl + url;
  }
  /// electron环境添加域名
  let userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('electron') > -1 && requestUrl.indexOf('http') == -1) {
    requestUrl = 'https://yimutodo.com' + requestUrl;
  }

  let _header = {'X-Requested-With': 'XMLHttpRequest','Accept':'*/*','_ajax_submit':'1'};

  if(header && header instanceof Object) {
    _header = Object.assign(_header, header);
  }

  let config = {
    method: method,
    url: requestUrl,
    headers: _header,
    validateStatus: function (status) {
      return status === 200; // 状态码!=200会 reject
    }
  };

  if(method === 'get') {
    config['params'] = params;
  } else {
    // post请求添加type标识
    if (params) {
      params['systemType'] = 'PC';
    }
    config['data'] = params;
  }

  return new Promise((res) => {
    axios.request(config).then(response => {
      let data = response.data;
      let code = data.errorCode;
      if (code == 0) {
        res(data.data ? data.data : {});
      } else {
        if (withoutJudge(url, params)) {
          if (code == 1000004) {
            router.push({name: 'login'});
            channelEvent('logout');
          } else {
            showErrorMessage(data.errorMessage);
          }
        }
        res(null);
      }
    }).catch(error => {
      showErrorMessage();
      console.log(error);
      res(null);
    });
  });
};

let apiErrorMessageShow = false;

const showErrorMessage = (message) => {
  if (!apiErrorMessageShow) {
    apiErrorMessageShow = true;
    setTimeout(() => {
      apiErrorMessageShow = false;
    }, 15000);
    Message.error(message && message.length > 0 ? message : '网络异常，请稍后重试');
  }
  // if(!apiErrorMessageShow) {
  //   apiErrorMessageShow = true;
  //   Modal.error({
  //     title: '出错啦',
  //     content: message && message.length > 0 ? message : '网络异常，请稍后重试',
  //     onOk: () => {
  //       apiErrorMessageShow = false;
  //     },
  //   });
  // }
}

/**
 * vue向electron传递消息
 * @param {*} eventName
 */
export const channelEvent = (eventName, params) => {
  if (isElectron) {
    let { ipcRenderer } = require('electron');
    ipcRenderer.send('channel-event', {'event': eventName, params});
    console.log(eventName, params);
  }
}
