<template>
  <Modal
    :mask="true"
    :width="420"
    v-model="showModal"
    draggable
    sticky
    scrollable
    title="选择地点"
    class="drag-modal"
    @on-ok="onModalOk">
    <Input 
      style="margin-bottom: 10px;"
      v-model="search" 
      placeholder="请输入地址">
      <template #prefix>
        <XIcon style="margin-top: 16px;" iconClass="search_gray" />
      </template>
    </Input>
    <a 
      type="text"
      style="color: #3D87DA;"
      @click="onAddClick">
      <Icon type="md-add-circle" />
      <span>&nbsp;&nbsp;新增一个地址</span>
    </a>
    <CellGroup
      style="max-height: 208px; overflow-y: scroll; margin-top: 5px;">
      <Cell
        :class="{'cell': true, 'select': item==selectModel}"
        v-for="(item, index) in filterList"
        :key="index"
        @click="onCellClick(item)"
        @contextmenu.prevent.stop="onMoreClick($event, item)">
        <Ellipsis :text="item.name || item.poiAddress" :length="23" />
        <template #label>
          <Ellipsis class="label" :text="item.totalAddress" :length="27" />
        </template>
        <template #extra>
          <!-- <XIcon 
            icon-class="more-col" 
            class="more-operate" 
            style="font-size: 16px;" 
            @click.stop="onMoreClick($event, item)"/> -->
            <Icon type="md-more" @click.stop="onMoreClick($event, item)" size="22" class="more-icon"/>
        </template>
      </Cell>
    </CellGroup>
    <Dropdown 
      ref="locationMenu"
      class="menu"
      transfer
      :style="{'left': menuOptions.x, 'top': menuOptions.y, position: 'fixed'}"
      trigger="click">
      <template #list>
        <DropdownMenu class="dropdown-list" >
          <DropdownItem @click="editModal=true">编辑</DropdownItem>
          <DropdownItem @click="deleteModal=true">删除</DropdownItem>
        </DropdownMenu>
      </template>
    </Dropdown>
    <XMapModal
      v-model="mapModal"
      @onModalOk="onMapModalOk"
    />
    <Modal 
      :width="420"
      v-model="editModal"
      @on-ok="onEditModalOk">
      <p style="font-size: 16px;">修改地址名称</p>
      <Input 
        style="margin-top: 12px;"
        clearable
        v-model="editText"/>
    </Modal>
    <Modal 
      :width="420"
      v-model="deleteModal"
      @on-ok="onDeleteModalOk">
      <p style="font-size: 16px;">删除地址</p>
      <p style="margin-top: 12px; font-size: 14px;">确定删除当前地址？</p>
    </Modal>
    <template #footer>
      <div
        style="display: flex; align-items: center;">
        <p style="flex: 1; text-align: left;">
          <a 
            style="color: #3D87DA;font-size: 14px;"
            @click="onClearClick">
            <span>清除已选地点</span>
          </a>
        </p>
        <i-button type="text" @click="showModal=false">取消</i-button>
        <i-button type="primary" @click="onModalOk">确定</i-button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { 
  addressAdd,
  addressUpdate,
  addressDelete,
  addressList
} from "@/common/api"
import XMapModal from "@/components/XMapModal"

export default {
  components: {
    XMapModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showModal: false,
      mapModal: false,
      editModal: false,
      deleteModal: false,

      search: '',
      selectModel: null,
      editModel: null,
      editText: '',
      locationList: [],

      menuOptions: {
        x: 0,
        y: 0
      },
    }
  },
  computed: {
    filterList() {
      if (!this.search || this.search.length == 0) {
        return this.locationList;
      }
      let list = [];
      this.locationList.forEach(item => {
        if ((item.name || item.poiAddress).indexOf(this.search) > -1 || item.totalAddress.indexOf(this.search) > -1) {
          list.push(item);
        }
      });
      return list;
    }
  },
  methods: {
    loadData() {
      addressList().then(res => {
        if (res) {
          this.$store.commit('updateAddresses', res);
          this.locationList = res;
        }
      });
    },
    onAddClick() {
      this.mapModal = true;
    },
    onCellClick(item) {
      this.selectModel = item;
    },
    onMoreClick(event, item) {
      console.log(event);
      this.editModel = item;
      this.editText = item.name || item.poiAddress;

      this.menuOptions.x = event.x + 'px';
      this.menuOptions.y = event.y + 'px';

      this.$refs.locationMenu.currentVisible = false;
      this.$nextTick(() => {
        this.$refs.locationMenu.currentVisible = true;
      });
    },
    onClearClick() {
      this.showModal = false;
      this.$emit('onModalOk', null);
    },
    onModalOk() {
      this.showModal = false;
      this.$emit('onModalOk', this.selectModel);
    },
    onMapModalOk(location) {
      if (location.lng && location.lat) {
        let model = {
          positionWeight: 1,
          poiAddress: location.name,
          latitude: location.lat,
          longitude: location.lng,
          totalAddress: location.address,
          userId: this.$store.state.user.userId,
        };
        addressAdd(model).then(res => {
          if (res) {
            model.addressIdStr = res;
            this.locationList.unshift(model);
            this.selectModel = model;
          }
        });
      }
    },
    onEditModalOk() {
      this.editModel.name = this.editText;
      addressUpdate(this.editModel);
    },
    onDeleteModalOk() {
      addressDelete(this.editModel.addressIdStr).then(res => {
        if (res) {
          let index = this.locationList.indexOf(this.editModel);
          this.locationList.splice(index, 1);
          if (this.selectModel.addressIdStr == this.editModal.addressIdStr) {
            this.selectModel = null;
          }
        }
      });
    }
  },
  watch: {
    modelValue(value) {
      this.showModal = value;
      if (value) {
        this.loadData();
      }
    },
    showModal(value) {
      this.$emit('update:modelValue', value);
    }
  }
}
</script>

<style lang="less" scoped>
  :deep(.ivu-input-icon) {
    padding: 10px 0px;
  }
  :deep(.ivu-input) {
    height: 48px;
    border-radius: 8px;
    color: var(--vp-c-text-1);
    background-color: var(--vp-c-bg-soft);
    font-size: 14px;
  }
  :deep(.ivu-cell-title){
    color: var(--vp-c-text-1);
  }
  :deep(.ivu-cell-label){
    color: var(--vp-c-text-2);
  }
  :deep(.ivu-cell:hover){
    background-color: #c2c2c241;
  }
  .cell {
    border-radius: 4px;
    padding: 7px 10px;
    &:hover {
      .more-operate {
        display: inline;
      }
    }
    &.select {
      background-color: #c2c2c241;
    }
    .input-bg {
      margin: 20px 10px;
      padding: 5px 0px;
      border-radius: 4px;
      background-color: #F6F6F6;
    }
    .title {
      font-size: 14px;
      color: black;
      margin-bottom: 4px;
    }
    .label {
      font-size: 12px;
      color: #A6A6A6;
    }
    .more-operate {
      display: none;
    }
  }
</style>