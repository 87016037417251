<template>
  <keep-alive>
    <router-view v-if="$route.meta.keepAlive"></router-view>
  </keep-alive>
  <router-view v-if="!$route.meta.keepAlive"></router-view>

  <Modal
    footerHide
    :width="420"
    v-model="showModal"
    :closable="false"
    :mask-closable="false"
  >
    <XClockInDialog />
  </Modal>
</template>

<script>
import { h } from "vue";
import { Modal } from "view-ui-plus";
import "element-plus/es/components/notification/style/css";
import { ElNotification } from "element-plus";
import XClockInDialog from "@/components/XClockInDialog";
import { channelEvent, getPCClientVersion } from "@/common/api";
export default {
  name: "App",
  components: { XClockInDialog, Modal },
  data() {
    return {
      incrementDate: {},
    };
  },
  computed: {
    showModal() {
      return this.$store.state.showClockDialog;
    },
  },
  mounted() {
    if (process?.env?.IS_ELECTRON) {
      setTimeout(() => {
        this.getRemoteVersion();
      }, 5000);
      this.initShortcut();
      this.ipcRendererInit();
    }
    const theme = localStorage.getItem('theme-appearance') || 'light'
    this.themeChange(theme)
  },
  methods: {
    themeChange(type) {
      const x = 0
      const y = 0
      const endRadius = Math.hypot(
        Math.max(x, innerWidth - x),
        Math.max(y, innerHeight - y)
      );

      document.documentElement.style.setProperty("--x", x + "px");
      document.documentElement.style.setProperty("--y", y + "px");
      document.documentElement.style.setProperty("--r", endRadius + "px");

      // 判断浏览器是否支持document.startViewTransition
      if (document.startViewTransition) {
        // 如果支持就使用document.startViewTransition方法
        document.startViewTransition(() => {
          this.classToggle(type);
        });
      } else {
        // 如果不支持，就使用最原始的方式，切换主题
        this.classToggle(type);
      }
    },
    classToggle(type){
      const htmlElement = document.querySelector('html')
      if (type === 'dark') {
        htmlElement.classList.remove('light');
        htmlElement.classList.add(type);
      } else {
        htmlElement.classList.remove('dark');
        htmlElement.classList.add(type);
      }
    },
    initShortcut() {
      const shortKey = localStorage.getItem("shortKey") || "CommandOrControl+Y";
      channelEvent("shortKey", shortKey);
    },
    ipcRendererInit() {
      const { ipcRenderer } = require("electron");
      ipcRenderer.on("notice", (event, data) => {
        this.incrementDate = data;
        this.notice();
      });
      ipcRenderer.on("updateNotice", () => {
        this.getRemoteVersion();
      });
    },
    handleLinkClick(event) {
      event.preventDefault();
      channelEvent("increment", JSON.parse(JSON.stringify(this.incrementDate)));
    },
    notice() {
      ElNotification({
        type: "success",
        title: `发现新版本${this.incrementDate.version}~`,
        message: h("div", [
          h("p", `${this.incrementDate.message}`),
          h(
            "a",
            {
              href: "#",
              style: {
                color: "#409EFF",
                display: "inline-block",
                float: "right",
                fontSize: "12px",
              },
              onClick: this.handleLinkClick,
            },
            "立即升级"
          ),
        ]),
        duration: 0,
        customClass: "custom-notification",
        dangerouslyUseHTMLString: false,
      });
    },
    onUpdateOk() {
      channelEvent("increment", JSON.parse(JSON.stringify(this.incrementDate)));
    },
    getRemoteVersion() {
      getPCClientVersion("PC_CLIENT_VERSION").then((res) => {
        const data = JSON.parse(res && res[0].value) || {};
        channelEvent("remoteVersion", data);
      });
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  height: 100%;
}
</style>
