<template>
  <div 
    :class="{'tab-node': true, 'hover': hover}" 
    @mouseenter="onMouseEnter" 
    @mouseleave="onMouseLeave"
    @click="onClick"
    @contextmenu.prevent.stop="onMoreClick">
    <XArrow ref="tagNodeArrow" :show="showArrow" :open="data.expand" @onArrowChanged="onArrowClick" />
    <!-- <XImage :url="data.iconUrl" /> -->
    <span :style="circleStyle"></span>
    <Text class="title" ellipsis :ellipsis-config="{ tooltip: true }">{{ data.tagName }}</Text>
    <!-- <Text v-show="!hover && data.progress && data.progress > 0" class="count" ellipsis>{{ data.progress }}</Text> -->
    <!-- <XIcon v-show="hover" icon-class="more-col" class="more-operate" style="font-size: 16px;" @click.stop="onMoreClick"/> -->
    <Icon v-show="hover" type="md-more" @click.stop="onMoreClick" size="22" class="more-icon"/>
  </div>
</template>

<script>

import XArrow from './XArrow';
// import XImage from './XImage'
import { colorFromValue } from '@/common/util';

export default {
  name: 'XTagNode',
  components: {
    XArrow,
    // XImage
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: () => {}
    }
  },
  inject: ['onTagMenuClick'],
  mounted() {
    
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    showArrow() {
      return this.data.list && this.data.list.length > 0;
    },
    circleStyle() {
      return {
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius: '4px',
        backgroundColor: this.data.color == 0 ? '#3D87DA' : colorFromValue(this.data.color),
        margin: '0 3px'
      };
    }
  },
  methods: {
    onMouseEnter() {
      this.hover = true;
    },
    onMouseLeave() {
      this.hover = false;
    },
    onClick() {
      this.$refs.tagNodeArrow.onArrowClick();
    },
    onAddClick() {
      this.onTagMenuClick('add');
    },
    onMoreClick(e) {
      let items = [{
        label: '编辑',
        onClick: () => this.onTagMenuClick('edit', this.data)
      }];
      if (!this.data.parentTagIdStr) {
        items.push({
          label: '添加子标签',
          onClick: () => this.onTagMenuClick('add', this.data)
        });
      }
      items.push({
        label: '删除',
        onClick: () => this.onTagMenuClick('delete', this.data)
      });

      e.preventDefault();
      this.$contextmenu({
        x: e.x,
        y: e.y,
        items: items
      });
    },
    onArrowClick(value) {
      let copyData = this.data;
      copyData.expand = value;
    }
  }
}
</script>

<style lang="less" scoped>
  .tab-node {
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    color: var(--vp-c-text-1);
    border-radius: 4px;
    padding: 0px 8px;
    position: relative;
    height: 40px;
    &:hover {
      background-color: #E8E8E833;
    }
    &.selected {
      background-color: #F5FAFF;
    }
    .title {
      font-size: 14px;
      font-weight: 400;
      flex: 1;
      margin: 0 4px;
      color: var(--vp-c-text-2);
    }
    .count {
      font-size: 14px;
      color: var(--vp-c-text-1);
    }

    .node-operate {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      border-radius: 8px;
      background-color: #fff;
      font-size: 18px;
      &.selected {
        background-color: #ECF1FF;
      }
    }
    .more-operate {
      color: var(--vp-c-text-2);
      font-size: 30px;
      margin-right: 4px;
    }
    .add-operate {
      color: #969EA8FF;
      font-size: 20px;
    }
    .menu-dropdown {
      position: absolute;
      left: 0px;
      top: 0px;
    }
    .menu-box {
      display: inline-block;
      width: 1px;
      height: 1px;
    }
  }
  .node-sub {
    padding-left: 16px;
  }
</style>