<template>
  <Content class="recycle">
    <Split v-model="split" :min="0.3" :max="0.4">
      <template #left>
        <XRecyclerList />
      </template>
      <template #trigger>
        <div class="trigger-bg">
          <div class="trigger"></div>
        </div>
      </template>
      <template #right>
        <XTaskDetail />
      </template>
    </Split>
  </Content>
</template>

<script>

import XTaskDetail from '@/components/XTaskDetail';
import XRecyclerList from '@/components/XRecyclerList';

export default {
  components: {
    XTaskDetail,
    XRecyclerList
  },
  data() {
    return {
      split: 0.5,
    }
  }
}
</script>

<style lang="less" scoped>
  .recycle {
    background-color: #f1f3ff;
    display: flex;
    .trigger-bg {
      padding: 0 3px;
      height: 100%;
      .trigger {
        width: 3px;
        height: 100%;
        background-color: transparent;
        cursor: col-resize;
      }
    }
  }
</style>